import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tabs } from "antd";
import "./tabs.scss";

const { TabPane } = Tabs;

export default function tabs(props) {
  const {history} = props;
  
  const matchRoute = useSelector(state => state.common.matchRoute);
  const match = useSelector(state => state.common.match);
  const [activeKey, setActiveKey] = useState(null);
  const tabChange = (activeKey) => {
    setActiveKey(activeKey);
    history.push(activeKey)
  }
  useEffect(() => {
    matchRoute.path &&  setActiveKey(matchRoute.path);
  }, [matchRoute])
  if (!matchRoute.secondTabs) return null;
  return (
    <div className="tabs-wrapper">
      <Tabs activeKey={activeKey} onChange={tabChange} className="second-tabs" size="default" tabBarStyle={{height: "50px"}}>
        {
          matchRoute.secondTabs.map((item, index) => {
            return <TabPane tab={item.name} key={item.key || index}></TabPane>
          })
        }
      </Tabs>
    </div>
  )
}