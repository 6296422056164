import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import QueueAnim from "rc-queue-anim";
import userModel from "store/reducers/userModel";
import AuthPanel from "./authPanel";
import { HotBusinessCard, HRCard } from "./Card";
import scrollTo from "utils/scrollTo";
import { Scrollbars } from "react-custom-scrollbars";
import "./home.scss";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;

@connect(
  ({ user }) => {
    return { user: user };
  },
  {
    ...userModel.actions,
  }
)
export default class Home extends React.Component {
  // static async getInitialProps({ pathname, query = {}, req, res }) {
  //   console.log("dsadadsddddddd")
  //   return {};
  // }
  state = {
    indexImgs: [],
    tabType: null,
    currentImg: "",
    recruitNames: ["批量招聘", "中/高端猎头", "测评/背调", "招聘/雇主品牌", "招聘网站"],
    outsourceNames: ["人员外包/派遣", "业务外包", "薪税优化", "人事代理", "招聘网站"],
    trainNames: ["员工培训", "团建拓展", "离职与转职", "人力咨询管理咨询", "招聘管理咨询", "薪酬体系咨询"],
    welfareNames: ["体检/医疗服务", "员工保险", "茶歇/活动物资", "办公室用品", "福利采购方案"],
    softwareNames: ["人事系统/EHR", "薪税/财务系统", "招聘/内推系统", "培训管理系统"],
    findServiceNames: ["提交需求", "平台审核", "服务商匹配", "洽谈合作"],
    findServiceSubNames: ["企业提交需求", "客服沟通确认发布", "提交需求", "提交需求"],
  };
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    document.title = "即派盒子-一站式人力资源服务聚合平台";
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  bannerClick = (e, item) => {
    // const {currentImg} = this.state;
    // if (currentImg) {
    //   window.open(currentImg);
    // }
  };

  onChange = (current) => {
    // const { indexImgs } = this.state;
    // this.setState({
    //   currentImg: indexImgs[current].url
    // })
  };

  handleScroll = (e) => {
    const header = document.getElementsByClassName("home-header")[0];
    const { scrollTop } = e.srcElement.scrollingElement || document.documentElement || document.body;
    const opacity = Math.round((scrollTop * 10) / 600) / 10;
    header.style.background = `rgba(14, 121, 251, ${opacity})`;
  };

  renderImgs = (count) => {
    const arr = [];
    for (let i = 1; i <= count; i++) {
      arr.push(<img src={require(`assets/imgs/home/home-content-logo-${i}.png`)} />);
    }
    return arr;
  };

  renderImgsAndText = (count, names, type) => {
    const arr = [];
    for (let i = 1; i <= count; i++) {
      arr.push(
        <div className="home-service-type">
          <img src={require(`assets/imgs/home/serviceType/home-${type}-${i}.png`)} />
          <div>{names[i - 1]}</div>
        </div>
      );
    }
    return arr;
  };

  renderFindServiceImgs = (count, names, subNames) => {
    const arr = [];
    for (let i = 1; i <= count; i++) {
      i % 2 === 0
        ? arr.push(
            <div className="home-how-find-service">
              <img src={require(`assets/imgs/home/home-right-arrow.png`)} />
            </div>
          )
        : arr.push(
            <div className="home-how-find-service">
              <img src={require(`assets/imgs/home/home-how-find-service-${(i + 1) / 2}.png`)} />
              <div className="home-how-find-service-title">{names[(i - 1) / 2]}</div>
              <div className="home-how-find-service-subTitle">{subNames[(i - 1) / 2]}</div>
            </div>
          );
    }
    return arr;
  };

  getNextRoute = () => {
    const { user } = this.props;
    return user && user.info && user.info.id ? "/position" : "/login";
  };

  render() {
    const NextUrl = this.getNextRoute();
    const { indexImgs, tabType, recruitNames, outsourceNames, trainNames, welfareNames, softwareNames } = this.state;
    const overPackOptions = {
      replay: true,
      always: true,
      playScale: 0.2,
    };
    return (
      <div className="home-content">
        <div className="home-content-banner">
          <div className="enterpriseServices">
            <div className="title">即派盒子企业服务:</div>
            <div className="subTitle">一站式企业找人力资源服务商对接平台</div>
            <div className="introduction">
              · &nbsp;聚合平台&nbsp;&nbsp; · &nbsp;免费发布&nbsp;&nbsp; · &nbsp;获取多家方案
            </div>
            <Link to={NextUrl} id="login-top">
              立即体验
            </Link>
          </div>
        </div>
        <div className="home-content-1">
          <div className="title">聚合服务商平台 免去多次对接成本</div>
          <div className="subTitle">集结全网乙方顾问，为企业提供一站式人力资源服务</div>
          <div className="imgs">{this.renderImgs(10)}</div>
        </div>
        <div className="home-content-2">
          <div className="title">25种人力资资服务类型，覆盖全国120+城市</div>
          <Tabs tabPosition="left" animated={false}>
            <TabPane tab="招聘服务" key="recruit">
              {this.renderImgsAndText(5, recruitNames, "recruit")}
            </TabPane>
            <TabPane tab="外包服务" key="outsource">
              {this.renderImgsAndText(4, outsourceNames, "outsource")}
            </TabPane>
            <TabPane tab="培训/咨询" key="trainAndConsult">
              {this.renderImgsAndText(6, trainNames, "train")}
            </TabPane>
            <TabPane tab="员工福利" key="welfare">
              {this.renderImgsAndText(5, welfareNames, "welfare")}
            </TabPane>
            <TabPane tab="软件系统" key="software">
              {this.renderImgsAndText(4, softwareNames, "software")}
            </TabPane>
          </Tabs>
        </div>
        <div className="home-content-3">
          <div className="enterprise-demand-left">
            <div className="title">企业需求一键全网发布</div>
            <div className="subTitle">多家供应商顾问同时对接、方案报价</div>
            <div className="subTitle">更快更省钱</div>
          </div>
          <div className="enterprise-demand-right">
            <img src={require(`assets/imgs/home/home-enterprise-demand.png`)} />
          </div>
        </div>
        <div className="home-content-4">
          <div className="title">为什么在即派盒子找服务商</div>
          <div className="bg">
            <div className="find-service-1">
              <div>01</div>
              <div>一站式</div>
              <div>25种类型 120+城市覆盖</div>
            </div>
            <div className="find-service-2">
              <div>02</div>
              <div>高性价比</div>
              <div>多家比价 多维对比</div>
            </div>
            <div className="find-service-3">
              <div>03</div>
              <div>响应快</div>
              <div>当天发布 当天对接</div>
            </div>
            <div className="find-service-4">
              <div>04</div>
              <div>平台免费</div>
              <div>全程不收费 直接对接服务商</div>
            </div>
          </div>
        </div>
        <div className="home-content-5">
          <div className="title">如何在平台找到服务商</div>
          <div className="find-service-content">
            {this.renderFindServiceImgs(7, this.state.findServiceNames, this.state.findServiceSubNames)}
          </div>
        </div>
        <div className="home-content-6">
          <div className="title">现在使用即派盒子</div>
          <div className="subTitle">免费对接多家服务商，提高效率，降低成本</div>
          <div className="experience">
            <Link to={NextUrl} id="login-bottom">
              立即体验
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
