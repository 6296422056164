import React, { useState, useRef } from "react";
import { Modal, message } from "antd";
import CustomIcon from "components/CustomIcon";
import useIsLogin from "hooks/useIsLogin";
import scrollTo from "utils/scrollTo"
import "./Card.scss";

const { info } = Modal;

export function HotBusinessCard(props) {
  const {item} = props;
  const [hoverStatus, setHoverStatus] = useState(false);
  const isLogin = useIsLogin();
  const cardEnter = () => {
    setHoverStatus(true);
  }
  const cardLeave = () => {
    setHoverStatus(false);
  }
  const scrollTopWindow = () => {
    if (isLogin) {
      // info({
      //   icon: <CustomIcon type="icon-ic_phone"/>,
      //   title: <span style={{fontWeight: "bold"}}>电话号码</span>,
      //   content: item.phone,
      //   okText: "确定"
      // })
    } else {
      scrollTo(0);
      message.info("请登录账号密码");
    }
  }
  return (
    <div className="hot-card card-style" onMouseEnter={cardEnter} onMouseLeave={cardLeave}>
      <div className="business-info clear">
        <div className="position ellipsis" title={item.post_name}>
          <span className="position-text ellipsis">{item.post_name}</span>
          <span className="service-type">{item.service_type_str}</span>
        </div>
        <div className="contact" style={{visibility: hoverStatus ? "visible" : "hidden"}} onClick={scrollTopWindow}>
          <CustomIcon type="icon-ic_lxfs" style={{marginRight: "4px", fontSize: "14px"}}/>获取联系方式
        </div>
      </div>
      <div className="company ellipsis" title={item.company}>
        <img src={require("assets/imgs/home/ic_gs.png")} /><span>{item.company}</span>
      </div>
      <div className="require ellipsis" title={`${item.business_desc}`}>需求简介：{item.business_desc}</div>
    </div>
  )
}


export function HRCard(props) {
  const {item} = props;
  const [hoverStatus, setHoverStatus] = useState(false);
  const cardEnter = () => {
    setHoverStatus(true);
  }
  const cardLeave = () => {
    setHoverStatus(false);
  }
  let positions = [];
  item.user_info.position && positions.push(item.user_info.position);
  item.user_info.company_short_name && positions.push(item.user_info.company_short_name);
  const scrollTopWindow = (e) => {
    e.stopPropagation();
    scrollTo(0);
    message.info("请登录账号密码");
  }
  return (
    <div className="hr-card card-style" onClick={scrollTopWindow} onMouseEnter={cardEnter} onMouseLeave={cardLeave}>
      <div className="hr-info clear">
        <div className="position">
          <div>
            <img src={item.user_info.avatar ? item.user_info.avatar : require("assets/imgs/default.png")} />
          </div>
          <div className="name">
            <div className="user-name ellipsis" title={item.user_info.user_name}>{item.user_info.user_name}</div> 
            <div className="detail" title={positions.join(" | ")}>
              <span className="pos-text ellipsis" title={item.user_info.position}>{item.user_info.position ? item.user_info.position : ""}</span>
              {item.user_info.position && item.user_info.company_short_name ? <span style={{fontSize: "12px", position: "relative", top: "-2px"}}>&nbsp;|&nbsp;</span> : null}
              <span className="short-text ellipsis" title={item.user_info.company_short_name}>{item.user_info.company_short_name ? item.user_info.company_short_name : ""}</span>
            </div>
          </div>
        </div>
        {/* <div className="tags">
          {(item.source_tag_custom || item.source_tag_name) && <div className="tag ellipsis" title={item.source_tag_custom || item.source_tag_name}>{item.source_tag_custom || item.source_tag_name}</div>}
          {(item.require_tag_custom || item.require_tag_name) && <div className="tag ellipsis" title={item.require_tag_custom || item.require_tag_name}>{item.require_tag_custom || item.require_tag_name}</div>}
        </div> */}
        <div className="contact" style={{visibility: hoverStatus ? "visible" : "hidden"}} onClick={scrollTopWindow}>联系TA</div>
      </div>
      {item.source && <div className="resource ellipsis" title={`我的资源：${item.source}`}>我的资源：{item.source}</div>}
      {item.require && <div className="require ellipsis" title={`我的需求：${item.require}`}>我的需求：{item.require}</div>}
    </div>
  )
}