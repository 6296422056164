import Model from "../Model";
import * as resumeService from "services/resumeService";
// import { message } from 'antd';

export default Model.getInstance(
  class extends Model {
    namespace = "resumeModel";
    state = {
      resumeList: [],
      resumeMeta: {},
      resumeCondition: {},
    };

    actions = {
      async getResumeList(param) {
        this.dispatch({
          type: "resumeModel/updateLoading",
          payload: true    
        });
        let condition = this.getState().resumeModel.resumeCondition;
        param.expand = "create_time_str,jd_hh_name,profile_info,last_communication"; 
        param["per-page"] = param["per-page"] || 10;
        param = {...condition, ...param}
        let result = {};
        result = await resumeService.getProfileList(param);
        if (result.code == 0) {
          this.dispatch({
            type: "resumeModel/updateList",
            payload: result.data  
          });
          this.dispatch({
            type: "resumeModel/updateCondition",
            payload: param
          });
        }
        this.dispatch({
          type: "resumeModel/updateLoading",
          payload: false
        });
      },
    };

    reducers = {
      updateList(state, { payload: data }) {
        return {
          ...state, 
          resumeList: data.list, 
          resumeMeta: data._meta, 
        };
      },
      updateResumeList(state, { payload: data }) {
        return {
          ...state, 
          resumeList: data, 
        };
      },
      updateCondition(state, { payload: data }) {
        return {
          ...state, 
          resumeCondition: {...data}, 
        };
      },
    };
  }
);
