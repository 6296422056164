import React, { PureComponent } from "react";
import { Icon } from "antd";
import './index.scss';
export default class ImgShow extends PureComponent {
  render() {
    return (
      <div>
        <div className="detail-view">
          <div className="detail-view-mask" />
          <div className="detail-view-wrapper">
            <div className="preview">
              <img src={this.props.previewSrc} alt="图片" />
              <div className="close-icon">
                <Icon
                  type="close-circle"
                  onClick={() => {
                    this.props.onCloseChild("imgSrc", false)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
