import React, { useState, useRef, useEffect} from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import useIsLogin from "hooks/useIsLogin";
import { sendMsg } from "services/userService";
import { useDispatch } from "react-redux";
import "./authPanel.scss";
const FormItem = Form.Item;
const size = "default";

export function PhoneCode(props) {
  const {className, formRef, userAction} = props;
  const [status, setStatus] = useState(61)
  const getCode = () => {
    let phone = formRef.getFieldValue("phone");
    console.log("codeclick")
    if (!new RegExp(/^1[3456789]\d{9}$/).test(phone)) {
      message.warning("请输入正确的手机号")
      return;
    }
    let count = 60;
    setStatus(count--);
    let countTime = setInterval(() => {
      if (count === 0) {
        setStatus(61);
        clearInterval(countTime);
        return;
      }
      setStatus(count--);
    }, 1000)
    sendMsg({
      phone: phone
    })
  }
  return (
    <div className={`${className} phone-code`}>
      <div className="split-line"></div>
      {
        status === 61 ? <div className="get-code" onClick={getCode}>获取验证码</div> :  <div className="count-time">{status}s</div>
      }
    </div>
  )
}


function RegisterForm(props) {
  const { getFieldDecorator, getFieldValue, validateFields } = props.form;
  const { userAction, history, accept } = props;
  const handleSubmit = (e) => {
    e.preventDefault();
    
    validateFields((err, values) => {
      if (!err) {
        if (!accept) {
          message.warning("请先同意用户协议")
          return;
        }
        console.log('Received values of form: ', values, history, userAction);
        userAction.regist(values).then(res => {
          if (res.code === 0) {
            history.push("/authentication");
          } else {
            message.error(res.errorMsg);
          }
        })
      }
    });
  }
  return (
    <React.Fragment>
      <div className="register-header">注册即派-猎头端<img src={require("assets/imgs/home/gbox_logo.png")} /></div>
      <Form className="register-form verify-form" onSubmit={handleSubmit}>
        <FormItem className="form-item" label="">
          {getFieldDecorator("phone", {
            rules: [
              { required: true, message: "请输入手机号" },
              { pattern: new RegExp(/^1[3456789]\d{9}$/), message: "手机号码格式不正确"},
            ]
          })(
            <Input
              placeholder="请输入手机号"
              size={size}
            />
          )}
        </FormItem>
        <FormItem className="form-item" label="">
          {getFieldDecorator("password", {
            rules: [
              { required: true, message: "请输入密码" },
            ]
          })(
            <Input
              placeholder="请输入密码(至少6位，数字、字母或'_')" type="password"
              size={size}
            />
          )}
        </FormItem>
        <div className="special-input">
          <FormItem className="form-item" label="">
            {getFieldDecorator("verify_code", {
              // initialValue: editClueData.company_name,
              rules: [
                { required: true, message: "请输入手机验证码" },
              ]
            })(
              <Input
                // onBlur={this.onBlurHandle}
                placeholder="请输入手机验证码"
                size={size}
                maxLength={6}
              />
            )}
          </FormItem>
          <PhoneCode className="register-get-code" formRef={props.form} userAction={userAction}/>
          <Button className="register-btn" type="primary" block htmlType="submit">免费注册</Button>
          
        </div>
      </Form>
    </React.Fragment>
  );
}


function PhoneLoginForm(props) {
  const { getFieldDecorator, getFieldValue, validateFields } = props.form;
  const { userAction, history, visible } = props;
  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values, history, userAction);
        userAction.phoneLogin(values).then(res => {
          if (res.code === 0) {
            history.push("/center");
          } else {
            message.error(res.errorMsg);
          }
        })
      }
    });
  }
  return (
    <Form style={{ display: visible ? "" : "none" }} className="login-phone-form verify-form" onSubmit={handleSubmit}>
      <FormItem className="form-item" label="">
        {getFieldDecorator("phone", {
          // initialValue: editClueData.company_name,
          rules: [
            { required: true, message: "请输入手机号" },
            { pattern: new RegExp(/^1[3456789]\d{9}$/), message: "手机号码格式不正确" },
          ]
        })(
          <Input
            // onBlur={this.onBlurHandle}
            placeholder="请输入手机号"
            size={size}
          />
        )}
      </FormItem>
      <div className="special-input">
        <FormItem className="form-item" label="">
          {getFieldDecorator("verify_code", {
            // initialValue: editClueData.company_name,
            rules: [
              { required: true, message: "请输入手机验证码" },
            ]
          })(
            <Input
              // onBlur={this.onBlurHandle}
              placeholder="请输入手机验证码"
              size={size}
              maxLength={6}
            />
          )}
        </FormItem>
        <PhoneCode className="register-get-code" formRef={props.form} userAction={userAction} />
      </div>
      <Button className="login-btn" type="primary" block htmlType="submit">立即登录</Button>
    </Form>
  );
}

function PasswordLoginForm(props) {
  const { getFieldDecorator, getFieldValue, validateFields } = props.form;
  const { userAction, history, visible } = props;
  const handlePSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values, history, userAction);
        userAction.phoneLogin(values).then(res => {
          if (res.code === 0) {
            history.push("/center");
          } else {
            message.error(res.errorMsg);
          }
        })
      }
    });
  }
  return (
    <Form style={{ display: visible ? "" : "none" }} className="login-password-form verify-form" onSubmit={handlePSubmit}>
      <FormItem className="form-item" label="">
        {getFieldDecorator("phone", {
          // initialValue: editClueData.company_name,
          rules: [
            { required: true, message: "请输入手机号" },
            { pattern: new RegExp(/^1[3456789]\d{9}$/), message: "手机号码格式不正确" },
          ]
        })(
          <Input
            // onBlur={this.onBlurHandle}
            placeholder="请输入手机号"
            size={size}
          />
        )}
      </FormItem>
      <div className="special-input">
        <FormItem className="form-item password-item" label="">
          {getFieldDecorator("password", {
            // initialValue: editClueData.company_name,
            rules: [
              { required: true, message: "请输入密码" },
            ]
          })(
            <Input
              // onBlur={this.onBlurHandle}
              placeholder="请输入密码" type="password"
              size={size}
            />
          )}
        </FormItem>
        <div className="register-get-code" style={{ color: "#0E79FB", fontSize: "13px" }} onClick={() => history.push("/reset")}>忘记密码</div>
      </div>
      <Button className="login-btn" type="primary" block htmlType="submit">立即登录</Button>
    </Form>
  )
}

const PhoneLogin = Form.create({})(PhoneLoginForm);
const PasswordLogin = Form.create({})(PasswordLoginForm);

function Login(props) {
  const { userAction, history, tabType } = props;
  const [type, setType] = useState(1)

  useEffect(() => {
    if (tabType) {
      setType(tabType);
    }
  }, [tabType])

  
  return (
    <React.Fragment>
      <div className="register-header">登录即派-猎头端<img src={require("assets/imgs/home/gbox_logo.png")} /></div>
      <div className="login-type">
        <div onClick={() => setType(1)} className={`phone-type ${type === 1 ? "active" : ""}`}>验证码登录<div className="activebar"></div></div>
        <div onClick={() => setType(2)} className={`password-type ${type === 2 ? "active" : ""}`}>账号密码登录<div className="activebar"></div></div>
      </div>
      <PhoneLogin visible={type === 1} userAction={userAction} history={history}/>
      <PasswordLogin visible={type === 2} userAction={userAction} history={history}/> 
    </React.Fragment>
  );
}

const Register = Form.create({})(RegisterForm);


export default function AuthPanel(props) {
  const {userAction, history, tabType} = props;
  const [panelType, setPanelType] = useState(1);
  const [accept, setAccept] = useState(false);

  const acceptClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    window.open("/agreement");
  }
  
  return (
    <div className="auth-panel-wrapper">
      <div className="auth-panel">
        {
          panelType === 2 && <div className="register">
            <Register userAction={userAction} history={history} accept={accept}/>
          </div>
        }
        {
          panelType === 1 && <div className="login">
            <Login tabType={tabType} userAction={userAction} history={history}/>
          </div>
        }
        {
          panelType === 2 && <div className="accept-login">
            <div className="agreement"><Checkbox onChange={(e) => setAccept(e.target.checked)}>接受<span onClick={acceptClick}>&lt;用户协议&gt;</span></Checkbox></div>
            <div className="go-login">已有账号？<a onClick={() => setPanelType(1)}>马上登录</a></div>
          </div>
        }
        {
          panelType === 1 && <div className="go-register">没有账号？<a onClick={() => setPanelType(2)}>立即注册</a></div>
        }
      </div>
    </div>
  );
}
