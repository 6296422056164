import React from "react";

export default function RenderCustomEmpty(props) {
  return (
    <div className="custom-render-empty">
      <img src={require("assets/imgs/empty.png")} />
      <div>{props && props.description ? props.description : "暂无数据"}</div>
    </div>
  );
}
