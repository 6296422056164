import Model from "../Model";
import dotProp from "dot-prop-immutable";
import * as commonService from "services/commonService";

export default Model.getInstance(
  class extends Model {
    namespace = "common";

    state = {
      systemInfos: [],
      unread: 0,
      matchRoute: {},
      match: {},
      currentAuth: true,
    };

    actions = {
    };

    reducers = {
      setSystemInfos(state, { payload: systemInfos }) {
        return dotProp.set(state, "systemInfos", systemInfos);
      },
      setUnread(state, { payload: data }) {
        return {...state, unread: data}
      },
      setMatchRoute(state, { payload: data }) {
        return {...state, matchRoute: data}
      },
      setMatch(state, { payload: data }) {
        return {...state, match: data}
      },
      setCurrentAuth(state, { payload: data }) {
        return {...state, currentAuth: data}
      }
    };
  }
);
