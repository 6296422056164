import React, { useContext, useEffect, useState } from "react";
import { Tabs, Col, Row, Card, Timeline } from "antd";
import * as positionService from "services/positionService";
import {PosContext} from '../PositionDetailDrawer';

const TabPane = Tabs.TabPane;

function createMarkup(html = "") {
  if (!html) html = "";
  console.log(html);
  return { __html: html.replace(/\r\n|\r|\n/g, "<br/>") };
}



export default function DetailRight(props) {
  const {posDetail = {}} = props;
  const posContext = useContext(PosContext);
  const [posRecord, setPosRecord] = useState({});
  useEffect(() => {
    positionService.getPosRecord({
      jd_id: posContext.posId
    }).then(res => {
      if (res.code === 0) {
        setPosRecord(res.data);
      }
    })
  }, [posContext.posId])

  const createWork = item => {
    let result = [];

    if (item.city_name === item.province_name) {
      item.province_name && result.push(item.province_name);
    } else {
      item.province_name && result.push(item.province_name);
      item.city_name && result.push(item.city_name);
    }
    item.region_name && result.push(item.region_name);
    return result.join("/");
  };

  return (
    <div className="detail-right-content">
      <Tabs className="detail-tab" defaultActiveKey="detail">
        <TabPane key="detail" tab="详细信息" className="scroll-pane detail-pane scroll-bar">
          <Card type="inner" title="职位信息" className="detail-card">
            <Row type="flex" align="top">
              <Col span={4}>职位名称：</Col>
              <Col className="flex-1">{posDetail.post_name ? posDetail.post_name : "-"}</Col>
            </Row>
            <Row type="flex" align="top">
              <Col span={4}>职能：</Col>
              <Col className="flex-1">{posDetail.position_name ? posDetail.position_name : "-"}</Col>
            </Row>
            <Row type="flex" align="top">
              <Col span={4}>需求人数：</Col>
              <Col className="flex-1">{posDetail.require_num ? posDetail.require_num : "-"}</Col>
            </Row>
            <Row type="flex" align="top">
              <Col span={4}>学历：</Col>
              <Col className="flex-1">{posDetail.education_name ? posDetail.education_name : "-"}</Col>
            </Row>
            <Row type="flex" align="top">
              <Col span={4}>服务类型：</Col>
              <Col className="flex-1">{posDetail.service_type_name ? posDetail.service_type_name : "-"}</Col>
            </Row>
            <Row type="flex" align="top">
              <Col span={4}>职位性质：</Col>
              <Col className="flex-1">{posDetail.work_type_name ? posDetail.work_type_name : "-"}</Col>
            </Row>
            <Row type="flex" align="top">
              <Col span={4}>工作经验：</Col>
              <Col className="flex-1">{posDetail.work_time_name ? posDetail.work_time_name : "-"}</Col>
            </Row>
            {posDetail.salary_structure && 
              <Row type="flex" align="top">
                <Col span={4}>薪酬结构：</Col>
                <Col className="flex-1">{posDetail.salary_structure ? posDetail.salary_structure : "-"}</Col>
              </Row>
            }
            {posDetail.salary_name && 
              <Row type="flex" align="top">
                <Col span={4}>薪资(元)：</Col>
                <Col className="flex-1">{posDetail.salary_name ? posDetail.salary_name : "-"}</Col>
              </Row>
            }
            {
              posDetail.jd_address && posDetail.jd_address.map((item, index) => {
                let city = createWork(item);              
                return (
                  <React.Fragment>
                    <Row type="flex" align="top">
                      <Col span={4}>工作地点{index ? index + 1 : ""}：</Col>
                      <Col className="flex-1">{city}</Col>
                    </Row>
                    <Row type="flex" align="top">
                      <Col span={4}>详细地址{index ? index + 1 : ""}：</Col>
                      <Col className="flex-1">{item.address}</Col>
                    </Row>
                  </React.Fragment>
                )
              })
            }
            {posDetail.jd_desc && 
              <Row type="flex" align="top">
                <Col span={4}>职位描述：</Col>
                <Col className="flex-1"><div dangerouslySetInnerHTML={createMarkup(posDetail.jd_desc)}></div></Col>
              </Row>
            }
            {posDetail.requirements && 
              <Row type="flex" align="top">
                <Col span={4}>任职要求：</Col>
                <Col className="flex-1">
                  <div dangerouslySetInnerHTML={createMarkup(posDetail.requirements)}></div>
                </Col>
              </Row>
            }
          </Card>
          <Card type="inner" title="项目经理" className="detail-card hh-card">
            <Row type="flex" align="top">
              <Col span={4}>项目经理：</Col>
              <Col className="flex-1">
                {posDetail.hh_name}{posDetail.hh_company ? `(${posDetail.hh_company})` : ""}
              </Col>
            </Row>
          </Card>
        </TabPane>
        <TabPane key="record" tab="操作记录" className="scroll-pane scroll-bar">
          <div className="position-progress-box collapse-handle-box">
            <Timeline>
              {posRecord.list &&
                posRecord.list.map((item, index) => {
                  return (
                    <Timeline.Item key={index}>
                      <div className="progress">
                        <p>
                          <b>{item.status_name}</b>
                        </p>
                        <p className="mark">
                          {item.remark && <span>备注：{item.remark}</span>}
                        </p>
                        {item.time_name && (
                          <p className="time">
                            {item.time_name}：{item.create_time_str}
                          </p>
                        )}
                      </div>
                    </Timeline.Item>
                  );
                })}
            </Timeline>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}
