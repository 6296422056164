import React from "react";
import { BackTop } from "antd";
import { connect } from "react-redux";
import { matchRoutes } from "react-router-config";
import Header from "./Header";
import Footer from "./Footer";
import ExFooter from "./ExFooter";
import userModel from "store/reducers/userModel";
import commonModel from "store/reducers/commonModel";
import "./BasicLayout.scss";

@connect(
  ({ common, user }) => {
    return { ...common, user };
  },
  {
    logout: userModel.actions.logout,
    checkUserInfo: userModel.actions.checkUserInfo,
    setMatchRoute: commonModel.actions.setMatchRoute,
    setMatch: commonModel.actions.setMatch,
    setCurrentAuth: commonModel.actions.setCurrentAuth,
  }
)
export default class BasicLayout extends React.PureComponent {
  state = {
  }
  componentDidMount() {
    const {checkUserInfo, history, route, location, setMatchRoute, setMatch} = this.props;
    const matchedRoutes = matchRoutes(route.routes, location.pathname);

    matchedRoutes.map(({ route, match }) => {
      setMatchRoute(route || {});
      setMatch(match || {});
      if (!route.noAuth) {
        checkUserInfo().then((res) => this.checkUserStatus(res, route));
      }
    });    
  }

  componentDidUpdate(prevProps) {
    const {route, history, location, checkUserInfo, setMatchRoute, setMatch} = this.props;
    const matchedRoutes = matchRoutes(route.routes, location.pathname);
    if (location.pathname !== prevProps.location.pathname) {

      matchedRoutes.map(({ route, match }) => {
        
        setMatchRoute(route || {});
        setMatch(match || {});
        if (!route.noAuth) {
          checkUserInfo().then((res) => this.checkUserStatus(res, route));
        }
      });
    }
  }

  checkUserStatus = (res, route) => {
    const {history, setCurrentAuth} = this.props;
    let user = res.data;
    // user.check_status = 0;
    if (res.code === 0 && user) {
      // user.check_status = 0;
      if (user.check_status !== 3) {
        setCurrentAuth(false);
      } else {
        setCurrentAuth(true);
      }

      if (user.check_status !== 3 && !route.noCheckStatus) {
        history.push("/authentication");
        return;
      }
    } else {
      setCurrentAuth(true);
    }
  }

  render() {
    const {
      children, user, location, history, matchRoute, logout, currentAuth
    } = this.props;
    const footer = matchRoute.noAuth ? <ExFooter /> : <Footer />;
    return (
      <div className="layout" style={{height: "100%", width: "100%"}}>
        <Header user={user} location={location} history={history} matchRoute={matchRoute} userAction={{logout}} currentAuth={currentAuth}/>
        <div className="layout-content">
          {children}
        </div>
        <ExFooter /> 
        <BackTop>
          <img src={require("assets/imgs/backtop.png")}/>
        </BackTop>
      </div>
    );
  }
}
