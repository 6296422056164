import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, message, Checkbox } from "antd";
import CustomIcon from "components/CustomIcon";
import PhoneCode from "./PhoneCode";
import AuthBg from "./AuthBg";
import userModel from "store/reducers/userModel";
import { useDispatch } from "react-redux";
import {PHONE_REG, VERIFY_CODE_REG} from 'utils/const';
import "./auth.scss";

const FormItem = Form.Item;
const size = "large";

function RegisterForm(props) {
  const { getFieldDecorator, validateFields } = props.form;
  const { userAction, history, modalClick } = props;
  const [accept, setAccept] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    
    validateFields((err, values) => {
      if (!err) {
        if (!accept) {
          message.warning("请先同意用户协议")
          return;
        }
        console.log('Received values of form: ', values, history, userAction);
        userAction.regist(values).then(res => {
          if (res.code === 0) {
            history.push("/authentication");
          } else {
            message.error(res.errorMsg);
          }
        })
      }
    });
  }
  const acceptClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    window.open("/agreement");
  }

  const goLogin = () => {
    if (modalClick) {
      modalClick(1);
    } else {
      history.push("/login");
    }
  }
  
  return (
    <div className="login-content">
      <div className="title">注册Geebox</div>
      <Form className="register-form verify-form" onSubmit={handleSubmit}>
        <FormItem className="form-item" label="">
          {getFieldDecorator("phone", {
            rules: [
              { required: true, message: "请输入手机号码" },
              { pattern: new RegExp(PHONE_REG), message: "请输入正确手机号码"},
            ]
          })(
            <Input prefix={<CustomIcon type="icon-ic_ph" style={{fontSize: "16px"}}/>}
              placeholder="请输入手机号码"
              size={size}
            />
          )}
        </FormItem>
        <div className="special-input">
          <FormItem className="form-item" label="">
            {getFieldDecorator("verify_code", {
              rules: [
                { required: true, message: "请输入验证码" },
                { pattern: new RegExp(VERIFY_CODE_REG), message: "请输入正确验证码" },
              ]
            })(
              <Input prefix={<CustomIcon type="icon-ic_yzm" style={{fontSize: "16px"}}/>}
                placeholder="请输入验证码"
                size={size}
                maxLength={6}
              />
            )}
          </FormItem>
          <PhoneCode className="register-get-code" formRef={props.form} userAction={userAction} params={{register: 1}}/>
          
        </div>
        <FormItem className="form-item" label="">
          {getFieldDecorator("password", {
            rules: [
              { required: true, message: "请输入密码" },
            ]
          })(
            <Input prefix={<CustomIcon type="icon-ic_mima" style={{fontSize: "16px"}}/>}
              placeholder="请输入密码" type="password"
              size={size}
            />
          )}
        </FormItem>
        <div className="agreement"><Checkbox onChange={(e) => setAccept(e.target.checked)}><span className="acc">同意用户使用协议 </span><span onClick={acceptClick}>《即派盒子平台服务协议》</span></Checkbox></div>
        <Button className="register-btn" type="primary" size={size} block htmlType="submit">注册</Button>
        <div className="accept-login">
          <div className="go-login">已有账号?&nbsp; <span style={{cursor: "pointer"}} onClick={goLogin}><a>直接登录</a><CustomIcon type="icon-ic_enter"/></span></div>
        </div>
      </Form>
      
    </div>
  );
}

export const RegisterPanel = Form.create({})(RegisterForm);

@connect(
  ({ user }) => {
    return { user: user };
  },
  {
    ...userModel.actions,
  }
)
export default class Register extends React.Component {

  render () {
    const {history, regist} = this.props;
    const Register = Form.create({})(RegisterForm);
  
    return (
      <AuthBg history={history}>
        <Register history={history} userAction={{regist}}/>
      </AuthBg>
    );
  }
}
