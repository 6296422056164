import xhr from "./index";

export function getProfileList(param) {
  return xhr({ url: "/hrpc/v1/profiles", body: param, method: "GET" });
}

export function getProfileDetail(param) {
  return xhr({ url: "/hrpc/v1/profiles/" + param.id, body: param, method: "GET" });
}

export function getProfileCommunication(param) {
  return xhr({ url: "/hrpc/v1/profile/communication-record", body: param, method: "GET" });
}

export function getProfileRecord(param) {
  return xhr({ url: "/hrpc/v1/profile/operation-record", body: param, method: "GET" });
}
