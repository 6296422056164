import React from "react";

export default function DetailHead(props) {
  const {posDetail} = props;

  return  <div className="detail-head">
    <div className="head-title">
      <span className="position ellipsis" title={posDetail.post_name}>{posDetail.post_name}</span>
      <span className="num">NO.{posDetail.jd_no}</span>
    </div>
    <div className="head-manager">HR：{posDetail.hr_name}</div>
    <div className="head-status"><span className="dot"></span>{posDetail.status_name}</div>
  </div>
}