import React from "react";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Button,
  message,
} from "antd";
import SecondTabs from "components/SecondTabs/tabs";
import userModel from "store/reducers/userModel";
import "./authentication.scss";

const FormItem = Form.Item;
const size = "default";
const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};



function ResetForm(props) {
  const { getFieldDecorator, getFieldValue, validateFields } = props.form;
  const { userAction, history, user = {}, commonData } = props;
  user.info = {};

  const leastSame = (rule, value, callback) => {
    let password = getFieldValue("password");
    let rePassword = getFieldValue("re_password");
    console.log(value, password, rePassword, "leastSame")
    if (rule.field === "password") {
      if (value && rePassword && value != rePassword) {
        callback("两次密码不一致");
      } else {
        callback()
      }
      return;
    } else if (rule.field === "re_password") {
      if (value && password && value != password) {
        callback("两次密码不一致");
      } else {
        callback()
      }
      return;
    }
    callback("")
    
  }
  const newPasswordChange = (e) => {
    let rePassword = getFieldValue("re_password");
    let password = e.target.value;
    console.log(rePassword, password, "213")
    setTimeout(() => {
      if (rePassword ) {
        validateFields(["re_password"])
      }
    }, 0);
    
  }
  const newRPasswordChange = (e) => {
    let password = getFieldValue("password");
    let rePassword = e.target.value;
    setTimeout(() => {
      if (password ) {
        validateFields(["password"])
      }
     
    }, 0);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);

        userAction.resetPwd(values).then((res) => {
          if (res.code === 0) {
            debugger
            history.push("/login");
          } else {
            message.error(res.errorMsg);
          }
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Form className="complete-auth-form auth-form" onSubmit={handleSubmit}>
        <FormItem className="form-item" label="当前密码"  {...formItemLayout}>
          {getFieldDecorator("old_password", {
            rules: [
              { required: true, message: "请输入当前密码" },
            ]
          })(
            <Input
              placeholder="请输入当前密码"  type="password"
              size={size} 
            />
          )}
        </FormItem>
        <FormItem className="form-item" label="新密码"  {...formItemLayout}>
          {getFieldDecorator("password", {
            rules: [
              { required: true, message: "请输入新密码" },
            ]
          })(
            <Input
              placeholder="请输入新密码"  type="password"
              size={size} 
              onChange={newPasswordChange}
            />
          )}
        </FormItem>
        <FormItem className="form-item" label="确认新密码"  {...formItemLayout}>
          {getFieldDecorator("re_password", {
            rules: [
              { required: true, message: "请确认新密码", validateTrigger: "onChange" },
              {
                validator: leastSame
              }
            ]
          })(
            <Input
              placeholder="请确认新密码"  type="password"
              size={size}
              onChange={newRPasswordChange}
            />
          )}
        </FormItem>

        <FormItem
          className="form-item btn-item"
          label=" "
          {...formItemLayout}
          colon={false}
        >
          <Button className="submit-btn" type="primary" block htmlType="submit">
            提交
          </Button>
        </FormItem>
      </Form>
    </React.Fragment>
  );
}


@connect(
  ({ user }) => {
    return { user: user.info || {} };
  },
  {
    ...userModel.actions,
  }
)
export default class Center extends React.Component {

  state = {
  };

  componentDidMount() {
    document.title = "账号密码";
  }

  render() {
    const {
      history,
      resetPwd
    } = this.props;
    const Reseter = Form.create({})(ResetForm);
    return (
      <div className=" inner-page-wrapper">
        <SecondTabs history={history} />
        <div className="center-content page-content">
          <div className="content-header">账号密码</div>
          <Reseter userAction={{resetPwd}} history={history}/>
        </div>
      </div>
    );
  }
}
