import React, { useState, useRef, useEffect } from "react";
import { Menu, message, Dropdown, Tooltip, Divider } from "antd";
import { Link } from "react-router-dom";
import CustomIcon from "components/CustomIcon";
import { Fragment } from "react";

export default function BasicHeader(props) {
  const { user, location, history, matchRoute, userAction, currentAuth } = props;
  const [isLogin, setIsLogin] = useState(false);
  const [taskPopVisible, setTaskVisible] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(null);
  const [showName, setShowName] = useState("");
  const taskContainer = useRef(null);
  const [isHome, setIsHome] = useState(null);

  const loginOut = () => {
    userAction.logout().then((res) => {});
  };

  const centerMenu = (
    <Menu>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" onClick={loginOut}>
          退出
        </a>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setIsLogin(!!user.info.id);
    if (user.info.id) {
      if (user.info.check_status === 3) {
        setShowName(user.info.user_name);
      } else {
        let phone = user.info.check_info ? user.info.check_info.phone : "";
        setShowName(phone.substring(0, 3) + "****" + phone.substring(7));
      }
    } else {
      setShowName("");
    }
  }, [user]);

  useEffect(() => {
    const { firstLevel, path, secondTabs } = matchRoute;
    console.log("matchRoute: ", matchRoute);
    firstLevel && setSelectedKeys(firstLevel);
    setIsHome(firstLevel === "home" || firstLevel === "offshoreOutsource")
  }, [matchRoute, location]);

  const menuClick = (e) => {
    if (!currentAuth) {
      message.warning("完成身份认证后才可进入")
      e.preventDefault();
    }
  };

  if (isHome) {
    return (
      <Fragment>
        <div className="home-header">
          <div className="home-header-left">
            <div className="logo">
              <img src={require("assets/imgs/home/home-logo.png")} />
            </div>
            <Divider type="vertical" />
            <div className="logo-subTitle">人力资源服务商聚合平台</div>
            <Menu theme="dark" mode="horizontal" selectedKeys={selectedKeys} className="nav-menu">
              <Menu.Item key="home">
                <Link to={`/`}>首页</Link>
              </Menu.Item>
              <Menu.Item key="offshoreOutsource">
                <Link to={`/offshoreOutsource`}>
                  离岸外包
                  <img className="home-header-reducer" src={require("assets/imgs/home/home-header-reducer.png")} />
                </Link>
              </Menu.Item>
            </Menu>
          </div>
          {
            !isLogin ?
            <div className="home-header-right">
              <Link to={`/register`}>注册</Link>
              <Link to={`/login`}>登录</Link>
            </div>
            :
            <div className="home-header-right">
              <span className="ellipsis name-text">{showName}</span>
              <img src={user.info.avatar ? user.info.avatar : require("assets/imgs/default.png")} />
            </div>
          }
          
        </div>
      </Fragment>
    )
  }

  return isLogin ? (
    <div className="layout-header-wrapper">
      <div className="layout-header">
        <div className="logo">
          <img src={require("assets/imgs/home/logo.png")} />
        </div>
        <div className="header-right site-right">
          <Fragment>
            <Fragment>
              <Menu theme="dark" mode="horizontal" selectedKeys={selectedKeys} className="nav-menu">
                <Menu.Item key="1">
                  <Link to={`/position`} onClick={menuClick}>
                    职位
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to={`/resume`} onClick={menuClick}>
                    简历
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to={`/srm`} onClick={menuClick}>
                    供应商
                  </Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to={`/authentication`}>个人中心</Link>
                </Menu.Item>
              </Menu>

              <div className="my-center">
                <div className="task" ref={taskContainer}>
                  <Tooltip
                    onVisibleChange={(visible) => setTaskVisible(visible)}
                    title={null}
                    overlayClassName="task-popover"
                    title="客服电话：0512-67610835"
                    getPopupContainer={() => taskContainer.current}
                  >
                    <div className="service-phone-wrapper">
                      <div>
                        <CustomIcon type="icon-customerservice" className="icon-task" />
                      </div>
                      <span className={taskPopVisible ? "hover" : ""}>客服</span>
                    </div>
                  </Tooltip>
                </div>
                <div className="center-split"></div>
                <div className="center">
                  <Dropdown overlay={centerMenu}>
                    <span className="center-inner">
                      <span className="ellipsis name-text">{showName}</span>
                      <img src={user.info.avatar ? user.info.avatar : require("assets/imgs/default.png")} />
                    </span>
                  </Dropdown>
                </div>
              </div>
            </Fragment>
          </Fragment>
        </div>
      </div>
    </div>
  ) :  null;
}
