import React from "react";
import { connect } from "react-redux";
import {
  Drawer,
} from "antd";

import DetailLayout from "./detail-drawer/DetailLayout";
import userModel from "store/reducers/userModel";

export const PosContext = React.createContext({});

@connect(
  ({ user }) => {
    return { user: user };
  },
  {
    ...userModel.actions,
  }
)
export default class PositionDetailDrawer extends React.Component {
  
  render() {
    const { visible, posId, posType, onClose } = this.props;
    
    return (
      <Drawer
        placement="right"
        className="position-detail-drawer"
        title={null} 
        onClose={onClose}
        visible={visible}
        closable={false}
        destroyOnClose
      >
        <PosContext.Provider value={{posId, posType}}>
          <DetailLayout/>
        </PosContext.Provider>
      </Drawer>
    );
  }
}
