import xhr from "./index";

//列表
export function reqSuppliersList(param) {
  return xhr({ url: "/hrpc/v1/suppliers", body: param, method: "get" });
}

//人选数据
export function reqSupplierData(param) {
  return xhr({ url: "/hrpc/v1/supplier/profile-data", body: param, method: "get" });
}

//人选数据
export function reqSupplierList(param) {
  return xhr({ url: "/hrpc/v1/supplier/list", body: param, method: "get" });
}

//项目经理列表
export function reqSupplierHHList(param) {
  return xhr({ url: "/hrpc/v1/supplier/hh", body: param, method: "get" });
}