import Model from "../Model";
import dotProp from "dot-prop-immutable";
import * as userService from "services/userService";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const history = process.env.BROWSER ? require("utils/history").default : null;


export default Model.getInstance(
  class extends Model {
    namespace = "user";

    state = {
      info: {},
      pathname: "",
    };

    actions = {
      async phoneLogin(param) {
        const res = await userService.login({
          ...param
        });
        if (res.code === 0) {
          this.dispatch({
            type: "setUserInfo",
            payload: res.data
          });
          //store user info to localStorage
          localStorage.setItem("user", JSON.stringify(res.data));

          // const pathname = this.getState().user.pathname;
        }
        return res;
      },

      async checkUserInfo() {
        const res = await userService.getUserInfo();
        if (res.code === 0) {
          this.dispatch({
            type: "setUserInfo",
            payload: res.data
          });
          //存储用户信息到localStorage上
          localStorage.setItem("user", JSON.stringify(res.data));
        } else if (res.code === 101 || res.code === 5112) {
          this.dispatch({
            type: "setUserInfo",
            payload: {}
          });
          //存储用户信息到localStorage上
          localStorage.removeItem("user");
        }
        return res;
      },

      async regist(param) {
        const res = await userService.regist(param);
        if (res.code === 0) {
        }
        return res;
      },

      async completeUserInfo(param) {
        const res = await userService.submitReview({
          ...param,
        });
        if (res.code === 0) {
        }
        return res;
      },

      async forgetPwdReset(param) {
        const res = await userService.forgetPwd({
          ...param
        });
        if (res.code === 0) {
        }
        return res;
      },

      async resetPwd(param) {
        const res = await userService.resetPwd({
          old_password: param.old_password,
          password: param.password,
          re_password: param.re_password
        });
        return res;
      },

      async logout() {
        const res = await userService.logout();
        if (res.code === 0) {
          this.dispatch({
            type: "setUserInfo",
            payload: {}
          });
          localStorage.removeItem("user");
          this.getState().user.pathname !== "/" ? history.replace("/") : !1;
        }
        return res;
      },
    };

    reducers = {
      setUserInfo(state, { payload: info }) {
        return dotProp.set(state, "info", info);
      },

      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },

      setPathname(state, { payload: pathname }) {
        return dotProp.set(state, "pathname", pathname);
      }
    };
  }
);
