import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomIcon from "components/CustomIcon";
import { Row, Col, Input, Menu, Message, Anchor } from "antd";
import * as resumeService from "services/resumeService";
import ClampLine from "components/clamp-line.js";
import PositionDetailDrawer from "pages/position/PositionDetailDrawer";
import fileDownload from "utils/fileDownload.js";
import "./style.scss";
const _ = require("underscore");

const { TextArea } = Input;
const { SubMenu } = Menu;
const { Link } = Anchor;

function createMarkup(html = "") {
  if (!html) html = "";
  console.log(html);
  return { __html: html.replace(/\r\n|\r|\n/g, "<br/>") };
}

export default function ResumeDetail(props) {
  const user = useSelector((state) => state.user);
  const { match = {}, history } = props;
  const { params = {} } = match;
  const [info, setData] = useState({
    base_info: {},
    certificate: [],
    education: [],
    job: {},
    project: [],
    resume_attachment: [],
    work: [],
  });
  const [communicate, setCommunicate] = useState([]);
  const [record, setRecord] = useState([]);
  const [drawer, setDrawer] = useState({});
  const [downloading, setDownloading] = useState(false);

  const createCity = item => {
    let result = [];

    if (item.city_label === item.province_label) {
      item.province_label && result.push(item.province_label);
    } else {
      item.province_label && result.push(item.province_label);
      item.city_label && result.push(item.city_label);
    }
    item.region_label && result.push(item.region_label);
    return result.join("");
  };


  useEffect(() => {
    document.title = "简历详情";
    getDetail();
    getCommunication();
    getRecord();
  }, []);

  async function getDetail() {
    let res;
    let paramsInfo = {
      id: params.id,
    };
    res = await resumeService.getProfileDetail(paramsInfo);
    if (res.code === 0) {
      let result = res.data;
      const {sex_label, birth, worktime, graduation_time, city, is_intern} = result.base_info;
      let baseRow = [];
      sex_label && baseRow.push(sex_label);
      birth && baseRow.push(birth + "生");
      

      if (is_intern) {
        result.workTitle = "实习经历"
        graduation_time && baseRow.push(graduation_time + "毕业");
      } else {
        result.workTitle = "工作经历"
        worktime && baseRow.push(worktime + "年工作经验");
      }

      result.base_info.baseRow = baseRow.join(" | ")
      result.base_info.cityName = createCity(city)
      

      setData(result);
    }
  }

  async function getCommunication() {
    let res;
    let paramsInfo = {
      profile_id: params.id,
      expand: "jd_hh_name,create_time_str,communication_time_str",
      "per-page": 9999,
    };
    res = await resumeService.getProfileCommunication(paramsInfo);
    if (res.code === 0) {
      let result = res.data.list;
      setCommunicate(result);
    }
  }

  async function getRecord() {
    let res;
    let paramsInfo = {
      profile_id: params.id,
      "per-page": 9999,
    };
    res = await resumeService.getProfileRecord(paramsInfo);
    if (res.code === 0) {
      let result = res.data.list;
      setRecord(result);
    }
  }

  const handleClick = (e, link) => {
    e.preventDefault();
    console.log(link);
  };

  const openPosDrawer = (item) => {
    // debugger
    setDrawer({
      drawerId: item.jd_id,
      drawerVisible: true,
      drawerType: 1,
    })
  }
  const onDrawerClose = () => {
    setDrawer({
      drawerId: 0,
      drawerVisible: false,
    })
  }

  const onDownload = (e) => {
    e.stopPropagation();
    // if (this.state.nowDownload) {
    //   Message.error("下载太快了");
    //   return;
    // }
    if (downloading) return;
    // 需要登录
    if (!!!Object.keys(user.info).length) {
      history.push("/login")
      return;
    }
    setDownloading(true);
    // this.state.nowDownload = true;
    fileDownload(`/hrpc/v1/profile/download?&profile_id=${params.id}`)
      .then(res => {
        // this.state.nowDownload = false;
        setDownloading(false);
      })
      .catch(res => {
        let startIndex = res.indexOf('{"code"');
        let endIndex = res.indexOf('"}') + 2;
        if (startIndex == -1 || endIndex == -1) {
          Message.error("异常错误发生");
          setDownloading(false);
          // this.state.nowDownload = false;
          return;
        }
        let resObj = JSON.parse(res.substring(startIndex, endIndex));
        if (resObj.code == 5112) {
          setDownloading(false);
          history.push("/login")
          // userLogin(true);
        } else {
          setDownloading(false);
          Message.error(resObj.msg);
        }
        // this.state.nowDownload = false;
      });
  };

  return (
    <div className="resume-detail-wrapper inner-page-wrapper">
      <div className="resume-detail-content">
        <div className="download-btn" onClick={onDownload}>
          <CustomIcon type="icon-download" className="down-icon" />
          下载附件
        </div>
        <div className="resume-section base-info" id="base">
          <div className="section-title">基本信息</div>
          <div className="section-content">
            <div className="name">{info.base_info.name}</div>
            <div className="base">{info.base_info.baseRow}</div>
            <div className="contact">
              {info.base_info.phone && <span>
                <CustomIcon type="icon-ic_jianli_dianhua" />
                {info.base_info.phone}
              </span>}
              {info.base_info.email && <span>
                <CustomIcon type="icon-ic_jianli_youxiang" />
                {info.base_info.email}
              </span>}
            </div>
          </div>
        </div>
        <div className="resume-section job-info" id="status">
          <div className="section-title">求职状态</div>
          <div className="section-content">
            {info.base_info.position_name && 
              <Row className="job-row">
                <Col className="label" span={3}>
                  职位：
                </Col>
                <Col>{info.base_info.position_name}</Col>
              </Row>
            }
            {info.job.available_label && 
              <Row className="job-row">
                <Col className="label" span={3}>
                  可到岗时间：
                </Col>
                <Col>{info.job.available_label}</Col>
              </Row>
            }
            {info.base_info.cityName && 
              <Row className="job-row">
                <Col className="label" span={3}>
                  现居地点：
                </Col>
                <Col>{info.base_info.cityName}</Col>
              </Row>
            }
          </div>
        </div>
        {info.work && info.work.length !== 0 &&
          <div className="resume-section exp-info" id="job">
            <div className="section-title">{info.workTitle}</div>
            <div className="section-content">
              {
                info.work && info.work.map(item => {
                  return <div className="exp-item">
                    <div className="exp-row">
                      <span className="time">{item.begin_time} - {item.end_time}</span>
                      <span className="company">{item.company_name}</span>
                      <span className="pos">{item.position}</span>
                    </div>
                    {item.work_desc &&
                      <div className="exp-detail">
                        <div className="exp-title">工作内容：</div>
                        <div
                          className="content"
                          dangerouslySetInnerHTML={createMarkup(item.work_desc)}
                        ></div>
                      </div>
                    }
                  </div>
                })
              }
            </div>
          </div>
        }
        {info.project && info.project.length !== 0 &&
          <div className="resume-section exp-info" id="experience">
            <div className="section-title">项目经历</div>
            <div className="section-content">
              {
                info.project && info.project.map(item => {
                  return <div className="exp-item">
                    <div className="exp-row">
                      <span className="time">{item.begin_time} - {item.end_time}</span>
                      <span className="company">{item.project_name}</span>
                    </div>
                    {item.company_name &&
                      <div className="exp-detail">
                        <div className="exp-title">所属公司：</div>
                        <div className="content">{item.company_name}</div>
                      </div>
                    }
                    {item.description &&
                      <div className="exp-detail">
                        <div className="exp-title">项目描述：</div>
                        <div
                          className="content"
                          dangerouslySetInnerHTML={createMarkup(item.description)}
                        ></div>
                      </div>
                    }
                    {item.duty &&
                      <div className="exp-detail">
                        <div className="exp-title">责任描述：</div>
                        <div
                          className="content"
                          dangerouslySetInnerHTML={createMarkup(item.duty)}
                        ></div>
                      </div>
                    }
                  </div>
                })
              }
              
            </div>
          </div>
        }
        {info.education && info.education.length !== 0 &&
          <div className="resume-section edu-info" id="edu">
            <div className="section-title">教育经历</div>
            <div className="section-content">
              {
                info.education && info.education.map(item => {
                  let row = [];
                  item.school_name && row.push(item.school_name);
                  item.education && row.push(item.education);
                  item.major_name && row.push(item.major_name);
                  item.is_unified_label && row.push(item.is_unified_label);
                  return <div className="edu-item">
                    <div className="edu-row">
                      <span className="time">{item.begin_time} - {item.end_time}</span>
                      <span className="edu-all">
                        {row.join(" · ")}
                      </span>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        }
        {info.certificate && info.certificate.length !== 0 &&
          <div className="resume-section exp-info" id="cer">
            <div className="section-title">证书经历</div>
            <div className="section-content">
              {
                info.certificate && info.certificate.map(item => {
                  return <div className="exp-item">
                    <div className="exp-row">
                      {item.date && <span className="time">{item.date}</span>}
                      {item.name && <span className="company">{item.name}</span>}
                    </div>
                    {item.desc && 
                      <div className="exp-detail">
                        <div className="exp-title">具体描述：</div>
                        <div
                          className="content"
                          dangerouslySetInnerHTML={createMarkup(item.desc)}
                        ></div>
                      </div>
                    }
                  </div>
                })
              }
              
            </div>
          </div>
        }
      </div>
      <div className="resume-detail-info">
        <div className="resume-anchor">
          <Anchor onClick={handleClick} className="resume-anchor-wrapper" bounds={50} offsetTop={50} affix={false}>
            <Link
              href="#base"
              title={
                <React.Fragment>
                  <CustomIcon type="icon-ic_jibenxinxi" />
                  <span>基本信息</span>
                </React.Fragment>
              }
            />
            <Link
              href="#status"
              title={
                <React.Fragment>
                  <CustomIcon type="icon-ic_qiuzhiyixiang" />
                  <span>求职状态</span>
                </React.Fragment>
              }
            />
            {info.work && info.work.length !== 0 &&
              <Link
                href="#job"
                title={
                  <React.Fragment>
                    <CustomIcon type="icon-ic_gongzuojingli" />
                    <span>{info.workTitle}</span>
                  </React.Fragment>
                }
              />
            }
            {info.project && info.project.length !== 0 &&
              <Link
                href="#experience"
                title={
                  <React.Fragment>
                    <CustomIcon type="icon-ic_xiangmujingyan" />
                    <span>项目经历</span>
                  </React.Fragment>
                }
              />
            }
            {info.education && info.education.length !== 0 &&
              <Link
                href="#edu"
                title={
                  <React.Fragment>
                    <CustomIcon type="icon-ic_jiaoyujingli" />
                    <span>教育经历</span>
                  </React.Fragment>
                }
              />
            }
            {info.certificate && info.certificate.length !== 0 &&
              <Link
                href="#cer"
                title={
                  <React.Fragment>
                    <CustomIcon type="icon-ic_zhengshujingli" />
                    <span>证书经历</span>
                  </React.Fragment>
                }
              />
            }
          </Anchor>
        </div>
        {communicate && communicate.length !== 0 && 
          <div className="resume-communicate">
            <div className="title">沟通记录</div>
            <div className="communicate-list scroll-bar">
              {
                communicate.map(item => {
                  return <div className="communicate-item">
                    <div className="detail">
                      <ClampLine className="des-clamp" text={item.content} buttons={false} lines={3} />
                    </div>
                    <div className="time">{item.communication_time_str} {item.jd_hh_name}</div>
                  </div>
                })
              }
            </div>
          </div>
        }
        {record && record.length !== 0 && 
          <div className="resume-record">
            <div className="title">操作记录</div>
            <div className="record-list scroll-bar">
              {
                record.map(item => {
                  return <React.Fragment>
                    {item.type === 1 ?
                      <div className="record-item ellipsis" title={`${item.create_time_str}${item.jd_hh_name} ${item.status_name}-${item.jd_name}`}>
                        <span className="detail">{item.create_time_str}{item.jd_hh_name} {item.status_name}-</span>
                        <span className="pos" onClick={() => openPosDrawer(item)}>{item.jd_name}</span>
                      </div>
                      :
                      <div className="record-item ellipsis" title={`${item.create_time_str}${item.jd_hh_name} 新增沟通记录`}>
                        <span className="detail">{item.create_time_str}{item.jd_hh_name} 新增沟通记录</span>
                      </div>
                    }
                  </React.Fragment>
                })
              }
            </div>
          </div>
        }
        <PositionDetailDrawer onClose={onDrawerClose} visible={drawer.drawerVisible} posId={drawer.drawerId} posType={drawer.drawerType}/>
      </div>
    </div>
  );
}
