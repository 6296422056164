import Model from "../Model";
import * as positionService from "services/positionService";
// import { message } from 'antd';

export default Model.getInstance(
  class extends Model {
    namespace = "positionModel";
    state = {
      positionList: [],
      positionMeta: {},
      positionCondition: {},
    };

    actions = {
      async getPositionList(param) {
        this.dispatch({
          type: "positionModel/updateLoading",
          payload: true    
        });
        let condition = this.getState().positionModel.positionCondition;
        param["per-page"] = param["per-page"] || 10;
        param = {...condition, ...param}
        let result = {};
        result = await positionService.reqPosList(param);
        if (result.code == 0) {
          this.dispatch({
            type: "positionModel/updateList",
            payload: result.data
          });
          this.dispatch({
            type: "positionModel/updateCondition",
            payload: param
          });
        }
        this.dispatch({
          type: "positionModel/updateLoading",
          payload: false
        });
      },
    };

    reducers = {
      updateList(state, { payload: data }) {
        return {
          ...state, 
          positionList: data.list, 
          positionMeta: {...data._meta, ...data.extra}, 
        };
      },
      updatePositionList(state, { payload: data }) {
        return {
          ...state, 
          positionList: data, 
        };
      },
      updateCondition(state, { payload: data }) {
        return {
          ...state, 
          positionCondition: {...data}, 
        };
      },
    };
  }
);
