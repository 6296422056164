import React,  { useContext, useEffect, useState } from "react";
import {
  Tabs,
  Pagination,
} from "antd";
import CustomizeRenderEmpty from "components/CustomEmpty";

import * as positionService from "services/positionService";
import {PosContext} from '../PositionDetailDrawer';

const TabPane = Tabs.TabPane;

const statusColor = {
  0: "#3790FC",
  2: "#FA9965",
  3: "#7DCB57",
  4: "#5FCCC3",
  5: "#57CB87",
  6: "#999999",
  7: "#999999",
  99: "#6377F8",
}

function PositionTalentCard(props) {
  const {talent = {}, onClick, activeProfile} = props;
  const {profile_info} = talent;

  let exper = [];
  if (profile_info.is_intern) {
    profile_info.education && exper.push(profile_info.education);
    profile_info.major && exper.push(profile_info.major);
  } else {
    profile_info.education && exper.push(profile_info.education);
    profile_info.worktime && exper.push(profile_info.worktime + "年经验");
  }
  
  
  let info = [];
  if (profile_info.is_intern) {
    profile_info.graduation_time && info.push(profile_info.graduation_time);
  } else {
    profile_info.city_str && info.push(profile_info.city_str);
    profile_info.salary_expect && info.push("期望薪资" + profile_info.salary_expect);
  }
  

  const itemClick = () => {
    onClick && onClick(talent);
  }

  const openResume = () => {
    window.open("/resume/" + talent.profile_id);
  }

  return (
    <div className={`talent-item ${activeProfile === talent.id ? 'active' : ''}`} onClick={itemClick}>
      <div className="name ellipsis" onClick={openResume}>{profile_info.name}</div>
      <div className="experience ellipsis">{exper.join("·")}</div>
      <div className="info ellipsis">{info.join("·")}</div>
      <div className="talent-status"><span className="dot" style={{backgroundColor: statusColor[talent.status]}}></span>{talent.status_name}</div>
    </div>
  );
}

function PositionRecordCard(props) {
  const {record = {}} = props;
  const [isExpand, setIsExpand] = useState(false);
  
  const switchExpand = () => {
    setIsExpand(!isExpand);
  }
  return (
    <div className="record-card" onClick={switchExpand}>
      <div className="record-status" style={{backgroundColor: statusColor[record.status]}}>{record.status_name}</div>
      <div className="record-detail">
        <div className={`record-reason ${isExpand ? '' : 'ellipsis'}`}>{record.event_name}{record.remark ? ("：" + record.remark) : ""}</div>
        <div className="record-time">{record.time_name}{record.event_time_str ? ("：" + record.event_time_str) : ""}</div>
        {!!record.communication_length && isExpand && <div className="record-time">时长：{record.communication_length}</div>}
      </div>
      <div className={`${isExpand ? 'record-arrow up' : 'record-arrow'}`}></div>
    </div>
  );
}

export default function DetailLeft(props) {
  const {posDetail = {}} = props;
  const {jd_profiles = {}} = posDetail;
  const posContext = useContext(PosContext);
  const [posType, setPosType] = useState({});
  const [posProfile, setPosProfile] = useState({_meta: {}});
  const [profileLogs, setProfileLogs] = useState({});
  const [activeProfile, setActiveProfile] = useState(0);

  const getProfile = (params) => {
    return positionService.getPosProfile({
      jd_id: posContext.posId,
      ...params,
      "per-page": 20,
    }).then(res => {
      if (res.code === 0) {
        setPosProfile(res.data)
      }
      return res;
    })
  }
  const changeProgress = (type) => {
    getProfile({
      page: 1,
      status: type,
    }).then(res => {
      if (res.code === 0) {
        talentClick(res.data.list ? res.data.list[0] : null)
      }
    })
    setPosType(type)
  }

  const talentClick = (talent) => {
    if(!talent) {
      setProfileLogs([]);
      return;
    }
    setActiveProfile(talent.id)
    positionService.getProfileLogs({
      jd_profile_id: talent.id
    }).then(res => {
      if (res.code === 0) {
        setProfileLogs(res.data)
      }
    })
  }

  const pageChange = (page) => {
    getProfile({
      page: page,
      status: posType,
    }).then(res => {
      if (res.code === 0) {
        talentClick(res.data.list ? res.data.list[0] : null)
      }
    })
  }

  useEffect(() => {
    
  }, [posContext.posId])

  useEffect(() => {
    changeProgress(posContext.posType)
    setPosType(posContext.posType)
  }, [posContext.posType])
  
  let pagination = {
    size: "small",
    showTotal: total => `共${total}条`,
    total: posProfile._meta.totalCount,
    pageSize: posProfile._meta.perPage || 20,
    onChange: pageChange,
    current: posProfile._meta.currentPage,
  }

  return (
    <div className="detail-left-content">
      <Tabs className="detail-tab">
        <TabPane tab="职位进展">
          <div className="progress-bar">
            <span onClick={changeProgress.bind(this, 1)} className={`${posType === 1 ? "active" : ""} item`}>共推荐 {jd_profiles.recommended_num}</span>
            <span> 》 </span>
            <span onClick={changeProgress.bind(this, 2)} className={`${posType === 2 ? "active" : ""} item`}>安排面试 {jd_profiles.arrange_interview_num}</span>
            <span> 》 </span>
            <span onClick={changeProgress.bind(this, 3)} className={`${posType === 3 ? "active" : ""} item`}>面试通过 {jd_profiles.interview_through_num}</span>
            <span> 》 </span>
            <span onClick={changeProgress.bind(this, 4)} className={`${posType === 4 ? "active" : ""} item`}>offer协调 {jd_profiles.offer_coordinate_num}</span>
            <span> 》 </span>
            <span onClick={changeProgress.bind(this, 5)} className={`${posType === 5 ? "active" : ""} item`}>入职 {jd_profiles.onboard_num}</span>
            <span> 》 </span>
            <span onClick={changeProgress.bind(this, 6)} className={`${posType === 6 ? "active" : ""} item`}>淘汰 {jd_profiles.obsolete_num}</span>
          </div>
          <div className="position-talent">
            <div className="talent-column column">
              <div className="title">职位人选</div>
              <div className="talent-list scroll-bar">
                {
                  posProfile.list && posProfile.list.length !== 0 ?
                  posProfile.list.map(item => <PositionTalentCard activeProfile={activeProfile} talent={item} onClick={talentClick}/>)
                  :
                  <CustomizeRenderEmpty/>
                }
              </div>
              <div className="pages">
                {posProfile.list && posProfile.list.length !== 0 && <Pagination {...pagination}/>}
              </div>
            </div>
            <div className="record-column column">
              <div className="title">跟进记录</div>
              <div className="talent-record scroll-bar">
                {
                  profileLogs.list && profileLogs.list.length !== 0 ?
                  profileLogs.list.map(item => <PositionRecordCard record={item}/>)
                  :
                  <CustomizeRenderEmpty />
                }
              </div>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}
