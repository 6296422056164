import React, {useState, useEffect, useRef} from "react";
import { connect } from "react-redux";
import { Button, Menu, Input, Table, Form, Icon, Popover, Spin, DatePicker, Select } from "antd";
import CustomIcon from "components/CustomIcon";
import SecondTabs from "components/SecondTabs/tabs";
import userModel from "store/reducers/userModel";
import resumeModel from "store/reducers/resumeModel";
import * as resumeService from "services/resumeService";
import * as supplierService from "services/supplierService";
import moment from "moment";
import InputComplete from "components/InputComplete";
import "./resume.scss";

const { SubMenu } = Menu;
const FormItem = Form.Item;
const Option = Select.Option;
const { RangePicker } = DatePicker;
const size = "default";

function SearchForm(props) {
  const { getFieldDecorator, setFieldsValue, resetFields, validateFields } = props.form;
  const { searchFunc, updateCondition, initParams = {} } = props;
  const [isExpand, setIsExpand] = useState(true);
  const hhRef = useRef(null);
  const supplierRef = useRef(null);

  useEffect(() => {
    if (initParams.jd_hh) {
      setFieldsValue({
        "jd_hh": initParams.jd_hh
      })
    }
    if (initParams.follow_from) {
      setFieldsValue({
        "follow": [moment(initParams.follow_from), moment(initParams.follow_to)]
      })
    }
    if (initParams.enter_from) {
      setFieldsValue({
        "enter": [moment(initParams.enter_from), moment(initParams.enter_to)]
      })
    }
  }, [initParams])
  
  const switchExpand = () => {
    setIsExpand(!isExpand);
  }
  const searchClick = () => {
    validateFields((err, values) => {
      if (!err) {
        if (values.enter && values.enter.length !== 0) {
          values.enter_from = values.enter[0].format("YYYY-MM-DD")
          values.enter_to = values.enter[1].format("YYYY-MM-DD")
          delete values.enter
        } else {
          values.enter_from = ""
          values.enter_to = ""
        }
        if (values.follow && values.follow.length !== 0) {
          values.follow_from = values.follow[0].format("YYYY-MM-DD")
          values.follow_to = values.follow[1].format("YYYY-MM-DD")
          delete values.follow
        } else {
          values.follow_from = ""
          values.follow_to = ""
        }
        values.page = 1;
        searchFunc(values)
      }
    });
  }
  const resetClick = () => {
    resetFields();
    updateCondition({});
    searchFunc({});
    hhRef && hhRef.current && hhRef.current.handlePosNameSearch("");
    supplierRef && supplierRef.current && supplierRef.current.handlePosNameSearch("");
  }
  const requestSupplier = (param) => {    
    return new Promise((resolve, reject) => {
      supplierService.reqSupplierList({
        name: param.name
      }).then(res => {
        let autoResult = {code: 0};
        if (res.code === 0) {
          autoResult.data = res.data && res.data.map(item => item.company_name);
          autoResult.dataSource = res.data;
        } else {
          autoResult.data = [];
          autoResult.dataSource = []
        }
        resolve(autoResult)
      })
    })
  }
  const requestSupplierHH = (param) => {    
    return new Promise((resolve, reject) => {
      supplierService.reqSupplierHHList({
        hh_name: param.name
      }).then(res => {
        let autoResult = {code: 0};
        if (res.code === 0) {
          autoResult.data = res.data && res.data.list.map(item => item.hh_name);
          autoResult.dataSource = res.data.list;
        } else {
          autoResult.data = [];
          autoResult.dataSource = []
        }
        resolve(autoResult)
      })
    })
  }
  return (
    <Form className="search-form" layout="inline">
      <FormItem
        className="form-item"
        label="关键词"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        {getFieldDecorator("keyword")(
          <Input
            placeholder="姓名/手机号/职位名称"
            onPressEnter={searchClick}
            suffix={<CustomIcon type="icon-search" style={{ color: "#999" }} />}
            size={size}
          />
        )}
      </FormItem>
      <FormItem
        className="form-item"
        label="录入时间"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        {getFieldDecorator("enter",{
          
        })(
          <RangePicker
            format="YYYY-MM-DD"
            placeholder={["开始日期", "结束日期"]}
          />
        )}
      </FormItem>

      <FormItem
        style={{ display: isExpand ? "" : "none" }}
        className="form-item"
        label="更新时间"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        {getFieldDecorator("follow", {
          
        })(
          <RangePicker
            format="YYYY-MM-DD"
            placeholder={["开始日期", "结束日期"]}
          />
        )}
      </FormItem>
      <FormItem
        style={{ display: isExpand ? "" : "none" }}
        className="form-item"
        label="供应商"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        {getFieldDecorator("supplier")(
          <InputComplete size={size}
            ref={supplierRef}
            requestFunc={requestSupplier}
            placeholder="请输入供应商"
            custom
            />
        )}
      </FormItem>
      <FormItem
        style={{ display: isExpand ? "" : "none" }}
        className="form-item"
        label="录入人"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        {getFieldDecorator("jd_hh")(
          <InputComplete size={size}
            requestFunc={requestSupplierHH}
            placeholder="请输入录入人"
            custom
            ref={hhRef}
            />
          // <Select placeholder="请输入项目经理" size={size} allowClear showSearch>
          //   {
          //     hhOptions && hhOptions.map(item => <Option value={item.hh_name}>{item.hh_name}</Option>)
          //   }
          // </Select>
        )}
      </FormItem>

      <FormItem
        className="form-item"
        label=" "
        colon={false}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Button className="btn reset-btn" onClick={resetClick}>
          重置
        </Button>
        <Button className="btn search-btn" type="primary" onClick={searchClick}>
          查询
        </Button>
        {isExpand && (
          <a className="expand-link" onClick={switchExpand}>
            收起 <Icon type="down" />
          </a>
        )}
        {!isExpand && (
          <a className="expand-link" onClick={switchExpand}>
            展开 <Icon type="up" />
          </a>
        )}
      </FormItem>
    </Form>
  );
}

const Searcher = Form.create({})(SearchForm);

@connect(
  ({ resumeModel, user }) => {
    return { ...resumeModel, user: user };
  },
  {
    ...userModel.actions,
    ...resumeModel.actions,
  }
)
export default class Resume extends React.Component {
  state = {
    communication: [],
    communicationLoading: true,
    initParams: {},
  };
  componentDidMount() {
    const { getResumeList, history, location, match } = this.props;
    document.title = "简历管理";
    let params = {
      page: 1,
    }
    if (location.state) {
      params = {...params, ...location.state}
      this.setState({
        initParams: location.state
      })
    }
    getResumeList(params);
  }

  componentDidUpdate(prevProps) {
  }
  
  componentWillUnmount() {
    this.props.updateCondition({})
  }

  pageChange = (page, pageSize) => {
    const { getResumeList } = this.props;
    getResumeList({
      page: page,
      "per-page": pageSize,
    });
  };

  pageSizeChange = (page, pageSize) => {
    const {getResumeList} = this.props;
    getResumeList({
      page: page,
      "per-page": pageSize,
    })
  };

  onRow = (record, index) => {
    // let that = this;
    return {
      onClick: (e) => {
        window.open("/resume/" + record.profile_id);
      }, // 点击行
    };
  };

  onVisibleChange = (visible, record, index) => {
    const {updateResumeList, resumeList} = this.props;
    
    if (visible) {
      if (!record.last_communication) {
        // resumeList[index].popupVisible = false;
        return;
      };
      resumeService.getProfileCommunication({
        profile_id: record.profile_id,
        expand: "jd_hh_name,create_time_str,communication_time_str",
        "per-page": 9999,
      }).then(res => {
        if (res.code === 0) {
          this.setState({
            communication: res.data.list,
            communicationLoading: false
          })
        }
      })
      // resumeList[index].popupVisible = visible;
    } else {
      this.setState({
        communication: [],
        communicationLoading: true
      })
      // resumeList[index].popupVisible = visible;
    }
    // updateResumeList(resumeList)
  }

  columns = [
    {
      title: "姓名",
      dataIndex: "profile_info.name",
      ellipsis: true,
      width: 70,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "性别",
      dataIndex: "profile_info.sex_label",
      width: 50,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "学历",
      dataIndex: "profile_info.education",
      width: 60,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "当前城市",
      dataIndex: "profile_info.city",
      ellipsis: true,
      width: 120,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "工作年限",
      dataIndex: "profile_info.worktime",
      ellipsis: true,
      width: 75,
      render: (text) => (text ? text + "年" : "-"),
    },
    {
      title: "目前职位",
      dataIndex: "profile_info.position_name",
      ellipsis: true,
      width: 80,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "最近工作单位",
      dataIndex: "profile_info.last_company",
      ellipsis: true,
      width: 150,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "沟通记录",
      dataIndex: "last_communication",
      ellipsis: true,
      render: (text, record, index) => {
        const {communication, communicationLoading} = this.state;
        const content = (
          
            <div className="popover-content-com" onClick={e => e.stopPropagation()}>
              <Spin spinning={communicationLoading}>
              {communication &&
                communication.map((item, cIndex) => {
                  return (
                    <div className="communication-item" key={cIndex}>
                      <div className="info">
                        <div className="communication-base">
                          <span className="ellipsis time" title={item.communication_time_str}>
                            {item.communication_time_str}
                          </span>
                          <span className="ellipsis name" title={item.jd_hh_name}>
                            操作人：{item.jd_hh_name}
                          </span>
                        </div>
                        <div className="communication-content">{item.content}</div>
                      </div>
                    </div>
                  );
                })}
              </Spin>
            </div>
        );
        return <React.Fragment>{text ? <Popover
          content={content}
          title={
            <div>
              <span>沟通记录</span>
            </div>
          }
          // visible={record.popupVisible}
          onVisibleChange={visible => this.onVisibleChange(visible, record, index)}
          placement="right"
          trigger="hover"
          overlayClassName="add-record-popup"
        >
          <div
            className="ellipsis communication-table-value"
          >
            {text}
          </div>
        </Popover> : "-"
        }</React.Fragment>
      },
    },
    {
      title: "录入时间",
      dataIndex: "create_time_str",
      width: 105,
    },
    {
      title: "录入人",
      dataIndex: "jd_hh_name",
      ellipsis: true,
      width: 100,
      render: (text) => (text ? text : "-"),
    }
  ];

  render() {
    const {
      history,
      resumeList,
      resumeMeta,
      getResumeList,
      updateCondition
    } = this.props;
    const { initParams } = this.state;
    return (
      <div className="resume-page inner-page-wrapper">
        <SecondTabs history={history} />
        <div className="resume-content-panel">
          <Menu mode="inline" className="resume-menu" defaultSelectedKeys={["myresume"]}
            defaultOpenKeys={["sub1"]}>
            <SubMenu
              key="sub1"
              title={
                <span>
                  <CustomIcon type="icon-container" />
                  <span>我的简历库</span>
                </span>
              }
            >
              <Menu.Item key="myresume">全部简历</Menu.Item>
            </SubMenu>
          </Menu>
          <div className="resume-wrapper">
            <div className="resume-search">
              <Searcher initParams={initParams} searchFunc={getResumeList} updateCondition={updateCondition}/>
            </div>
            <Table
              rowKey="id"
              className="resume-table"
              // rowClassName={(record) => {
              //   return activeKey === "1" && record.is_get ? "is-get" : "";
              // }}
              pagination={{
                size: "small",
                showSizeChanger: true,
                showQuickJumper: true,
                total: resumeMeta.totalCount,
                pageSize: resumeMeta.perPage || 10,
                onChange: this.pageChange,
                onShowSizeChange: this.pageSizeChange,
                current: resumeMeta.currentPage,
              }}
              onRow={this.onRow}
              columns={this.columns}
              dataSource={resumeList}
            />
            <span className="page-total"><span className="line"></span>{`总计${resumeMeta.totalCount}个`}</span>
          </div>
        </div>
      </div>
    );
  }
}
