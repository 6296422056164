import React from "react";
import { AutoComplete } from "antd";
const { Option } = AutoComplete;

export default class InputComplete extends React.PureComponent {
  state = {
    positionName: {}
  };

  /**
   * @description 获取职位名
   * @param {string} value
   */
  positionNameFetch = (value, callback) => {
    const {
      requestFunc,
      setSearchVal = undefined,
      setSearchExpand = {},
      hideValue = false
    } = this.props;
    if (this.posNameTimeout) {
      clearTimeout(this.posNameTimeout);
      this.posNameTimeout = null;
    }
    this.posNameCurValue = value;

    const fake = () => {
      if (!value) {
        return callback([]);
      }

      this.setState({
        positionName: {
          ...this.state.positionName
        }
      });
      /**
       * @description 请求数据
       */
      requestFunc({
        name: value,
        company_id: setSearchVal,
        ...setSearchExpand
      }).then(res => {
        if (this.posNameCurValue === value && res && res.code === 0) {
          if (JSON.stringify(setSearchExpand) !== "{}") {
            let data = res.data.list || [];
            let result = data.map(res => res.name);
            result = hideValue ? result.filter(text => text.name !== value) : result;
            callback(result, res.dataSource);
          } else {
            let result = res.data || [];
            result = hideValue ? result.filter(text => text !== value) : result;
            console.log(result, "rr");
            callback(result, res.dataSource);
          }
        }
        this.setState({
          positionName: {
            ...this.state.positionName,
            fetching: false
          }
        });
      });
    };

    this.posNameTimeout = setTimeout(fake, 300);
  };
  /**
   * @description 搜索赋值
   */
  handlePosNameSearch = value => {
    this.positionNameFetch(value, (data, dataSource) => {
      this.setState({
        positionName: {
          ...this.state.positionName,
          options: data,
          optionDataSource: dataSource,
        }
      });
    });
  };
  
  render() {
    const { dataSource, onSearch, custom, ...props } = this.props;
    const { positionName } = this.state;
    let children = [];
    if (custom) {
      children = positionName.options ? positionName.options.map((item, index) => {
        return <Option key={item} title={item} source={positionName.optionDataSource[index]}>{item}</Option>
      }) : [];
    };
    return (
      <React.Fragment>
      {
        custom ?
        <AutoComplete
          {...props}
          onSearch={this.handlePosNameSearch}
        >
          {children}
        </AutoComplete>
        :
        <AutoComplete
          {...props}
          dataSource={positionName.options}
          onSearch={this.handlePosNameSearch}
        />
      }
      </React.Fragment>
    );
  }
}
