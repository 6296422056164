import React from "react";
import { connect } from "react-redux";
import { Row, Col, Tabs, Table, Input, Tooltip } from "antd";
import CustomIcon from "components/CustomIcon";
import SecondTabs from "components/SecondTabs/tabs";
import userModel from "store/reducers/userModel";
import positionModel from "store/reducers/positionModel";
import moment from "moment";
import PositionDetailDrawer from "./PositionDetailDrawer";
import {parseQueryString} from "utils/url"
import "./position.scss"

const { TabPane } = Tabs;
const { Search } = Input;

@connect(
  ({ user, positionModel }) => {
    return { user: user, ...positionModel };
  },
  {
    ...positionModel.actions,
    ...userModel.actions,
  }
)
export default class Position extends React.Component {

  state = {
    activeKey: "2",
    drawerId: 0,
    drawerVisible: false,
    drawerType: 1,
    keyword: "",
  }
  componentDidMount() {
    const {getPositionList, history, location, match} = this.props;
    document.title = '职位管理';
    let query = parseQueryString(location.search);
    let param = {
      status: query.type || "2",
      page: 1
    };
    if (location.state) {
      param = {...param, ...location.state}
    }
    
    getPositionList(param)
    this.setState({
      activeKey: query.type || "2",
      keyword: location.state ? location.state.keyword : "",
    })
  }

  componentDidUpdate(prevProps) {
    // const {getPositionList, history, location, match} = this.props;

    // if (match.params.type !== prevProps.match.params.type) {
    //   getPositionList({
    //     scene: parseInt(match.params.type),
    //     page: 1
    //   })
    // }
  }

  componentWillUnmount() {
    this.props.updateCondition({})
  }

  keywordChange = (e) => {
    this.setState({
      keyword: e.target.value
    })
  }
  keywordSearch = (value) => {
    const {getPositionList} = this.props;
    getPositionList({
      keyword: value,
      page: 1,
    })
  }

  pageChange = (page, pageSize) => {
    const {getPositionList} = this.props;
    getPositionList({
      page: page,
      "per-page": pageSize,
    })
  }

  pageSizeChange = (page, pageSize) => {
    const {getPositionList} = this.props;
    getPositionList({
      page: page,
      "per-page": pageSize,
    })
  };

  tabChange = (activeKey) => {
    const {getPositionList} = this.props;
    getPositionList({
      keyword: "",
      page: 1,
      status: activeKey
    })
    this.setState({
      activeKey: activeKey,
      keyword: "",
    })
  }

  openDialog = (type, record, num, e) => {
    e.stopPropagation();
    // if (num <= 0) return;
    // window.open("/positionDetail/" + record.id);
    this.setState({
      drawerId: record.id,
      drawerVisible: true,
      drawerType: type,
    })
  }

  onDrawerClose = () => {
    this.setState({
      drawerId: 0,
      drawerVisible: false,
    })
  }
  
  onRow = record => {
    return {
      onClick: () => {
        this.setState({
          drawerId: record.id,
          drawerVisible: true,
          drawerType: 1,
        })
      }
    };
  }

  render() {
    const { history, positionList, positionMeta = {}, positionCondition = {} } = this.props;
    const { activeKey, drawerId, drawerVisible, drawerType } = this.state;
    const columns = [
      {
        title: "职位名称",
        dataIndex: "post_name",
        ellipsis: true,
        width: 130,
      },
      {
        title: "职位状态",
        dataIndex: "status_name",
        ellipsis: true,
        width: 160,
        render: (text, record) => text + `（时长${record.progress_days}天）`
      },
      {
        title: "工作地点",
        dataIndex: "hh_id",
        ellipsis: true,
        width: 120,
        render: (text, record) => {
          let cityName = record.jd_address ? record.jd_address.map(item => {
            return item.city_name
          }) : []
          return cityName.join("/")
        }
      },
      {
        title: "职位进展",
        className: "position-progress",
        align: "center",
        children: [
          {
            title: "共推荐",
            className: "position-progress-item",
            dataIndex: "jd_profiles.recommended_num",
            align: "center",
            render: (text, record) => <span onClick={this.openDialog.bind(this, 1, record, text)}>{text ? text : "-"}</span>,
          },
          {
            title: "安排面试",
            className: "position-progress-item",
            dataIndex: "jd_profiles.arrange_interview_num",
            align: "center",
            render: (text, record) => <span onClick={this.openDialog.bind(this, 2, record, text)}>{text ? text : "-"}</span>,
          },
          {
            title: "面试通过",
            className: "position-progress-item",
            dataIndex: "jd_profiles.interview_through_num",
            align: "center",
            render: (text, record) => <span onClick={this.openDialog.bind(this, 3, record, text)}>{text ? text : "-"}</span>,
          },
          {
            title: "offer协调",
            className: "position-progress-item",
            dataIndex: "jd_profiles.offer_coordinate_num",
            align: "center",
            render: (text, record) => <span onClick={this.openDialog.bind(this, 4, record, text)}>{text ? text : "-"}</span>,
          },
          {
            title: "入职",
            className: "position-progress-item",
            dataIndex: "jd_profiles.onboard_num",
            align: "center",
            width: 60,
            render: (text, record) => <span onClick={this.openDialog.bind(this, 5, record, text)}>{text ? text : "-"}</span>,
          },
          {
            title: "淘汰",
            className: "position-progress-item",
            dataIndex: "jd_profiles.obsolete_num",
            align: "center",
            width: 60,
            render: (text, record) => <span onClick={this.openDialog.bind(this, 6, record, text)}>{text ? text : "-"}</span>,
          },
        ]
      },
      {
        title: "HR",
        dataIndex: "hr_name",
        width: 120,
        ellipsis: true,
      },
      {
        title: "顾问",
        dataIndex: "hh_name",
        width: 120,
        ellipsis: true,
        render: (text, record) => {
          let title = (
            <div className="hh-tip">
              <div>{record.hh_company}</div>
              <div>{record.hh_phone}</div>
            </div>
          )
          return <Tooltip title={title}>
            {text}
          </Tooltip>
        }
      },
      
    ];
    return (
      <div className="position-page inner-page-wrapper">
        <SecondTabs history={history}/>
        <div className="content-panel">
          <Tabs activeKey={activeKey} className="position-tabs" type="card" onChange={this.tabChange}>
            <TabPane tab={`进行中(${positionMeta.processing || 0})`} key="2" className="position-tab-panel">
            </TabPane>
            <TabPane tab={`已暂停(${positionMeta.paused || 0})`} key="3" className="position-tab-panel">
            </TabPane>
            <TabPane tab={`已关闭(${positionMeta.closed || 0})`} key="4" className="position-tab-panel">
            </TabPane>
          </Tabs>
          <div className="position-wrapper">
            <Row className="filter-search" type="flex" align="middle">
              <Col span={1}>搜索：</Col>
              <Col span={6}>
                <Search
                  placeholder="搜索顾问" value={this.state.keyword}
                  onChange={this.keywordChange}
                  onSearch={this.keywordSearch}
                />
              </Col>
            </Row>
            <Table rowKey="id" className="position-table"
              onRow={this.onRow}
              pagination={{
                size: "small",
                showSizeChanger: true,
                showQuickJumper: true,
                total: positionMeta.totalCount,
                pageSize: positionMeta.perPage || 10,
                onChange: this.pageChange,
                onShowSizeChange: this.pageSizeChange,
                current: positionMeta.currentPage
              }}
              columns={columns} dataSource={positionList}/>
            <PositionDetailDrawer onClose={this.onDrawerClose} visible={drawerVisible} posId={drawerId} posType={drawerType}/>
          </div>
        </div>
      </div>
    );
  }
}
