import React from "react";
import { Switch, Route } from "react-router-dom";
import BasicLayout from "pages/layout/BasicLayout";
import { Abnormal404, Abnormal500 } from "pages/abnormal/abnormal";
import Home from "pages/home/home";

import Position from "pages/position/position";

import Resume from "pages/resume/resume";
import ResumeDetail from "pages/resumeDetail/resumeDetail";

import Supplier from "pages/supplier/supplier";

import Authentication from "pages/auth/authentication";
import CenterInfo from "pages/auth/centerInfo";
import Reset from "pages/auth/resetPassword";
import Login from "pages/auth/login";
import Register from "pages/auth/register";
import OffshoreOutsource from 'pages/offshoreOutsource/offshoreOutsource';

import Agreement from "pages/agreement/agreement";

const positionTabs = [
  {name: "职位管理", key: "/position"}
]
const resumeTabs = [
  {name: "简历管理", key: "/resume"}
]
const srmTabs = [
  {name: "我的供应商", key: "/srm"}
]
const myTabs = [
  {name: "认证信息", key: "/authentication"},
  {name: "账户密码", key: "/centerinfo"}
]

/**
 * noAuth 不需要登录
 * noCheckStatus 不需要用户认证状态
 */
const Routes = () => (
  <Switch>
    <Route path="/login" component={Login} noAuth={true} />
    <Route path="/register" component={Register} noAuth={true} />
    <Route path="/reset" component={Reset} noAuth={true}/>
    <Route path="/" component={BasicLayout} >
      <Route path="/" component={Home} exact noAuth={true} firstLevel="home"/>
      <Route path="/offshoreOutsource" component={OffshoreOutsource} noAuth={true} firstLevel="offshoreOutsource"/>
      <Route path="/agreement" component={Agreement} noAuth={true}/>
      <Route path="/authentication" component={Authentication} noCheckStatus={true} firstLevel="4" secondTabs={myTabs}/>
      <Route path="/centerinfo" component={CenterInfo} noCheckStatus={true} firstLevel="4" secondTabs={myTabs}/>

      <Route path="/resume" exact component={Resume} firstLevel="2" secondTabs={resumeTabs}/>
      <Route path="/resume/:id"  component={ResumeDetail}/>

      <Route path="/position" component={Position} firstLevel="1" secondTabs={positionTabs}/>

      <Route path="/srm" component={Supplier} firstLevel="3" secondTabs={srmTabs}/>      
      <Route path="/500" component={Abnormal500} noAuth={true}/>
      <Route component={Abnormal404} noAuth={true}/>
    </Route>
  </Switch>
);

export default Routes;
