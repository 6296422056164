/*
 * @Descripttion: 离岸外包
 * @Date: 2021-04-15 09:20:14
 * @LastEditors: ci25578
 * @LastEditTime: 2021-04-16 17:38:08
 */
import React, { Component, Fragment } from "react";
import { Carousel } from "antd";
import { Link } from "react-router-dom";
import "./offshoreOutsource.scss";
import userModel from "store/reducers/userModel";
import { connect } from "react-redux";

@connect(
  ({ user }) => {
    return { user: user };
  },
  {
    ...userModel.actions,
  }
)
class OffshoreOutsource extends Component {
  constructor() {
    super();
    this.state = {
      serviceTitles: ["人员招聘", "市场分析", "人才库盘点"],
      serviceTitlesTop: [["· 访寻推荐", "· 结构化面试"], "· 行业职位信息mapping", "· 招聘渠道维护"],
      serviceTitlesMid: [["· 薪酬谈判", "· 背景调查"], "· 竞争对手薪资架构分析", "· s人才库盘活"],
      serviceTitlesBottom: [["· Offer发放", "· 入离职访谈"], "· 业内人才流向分析", ""],
      solveTitles: ["招聘人手紧", "招聘压力大", "需求紧急", "预算紧"],
      solveSubTitlesTop: ["HR琐碎事务工作多", "招聘资源无法满足业务需求", "招聘进度缓慢", "招聘预算有限"],
      solveSubTitlesBottom: [
        "人力资源部门人手不足",
        "季节性、项目型大批量用工",
        "招募人员质量低",
        "传统猎企合作费用高",
      ],
      worthTitles: ["提升招聘效率", "节约成本", "快捷灵活", "提升管理效率"],
      worthSubTitlesTop: [
        "500+专业Recruiter远程支持，",
        "灵活配置招聘人手，多种服务方式，",
        "不占用企业编制，即租即用，",
        "减轻人事部门招聘压力，聚焦到",
      ],
      worthSubTitlesBottom: [
        "有效缓解招聘压力",
        "降低综合用工成本",
        "即用即停，用工更灵活",
        "核心管理中，提高企业管理效率",
      ],
      chargeTitles: ["PO（固定收费）", "Mass（结果收费）", "IO（变动收费）"],
      chargeSubTitlesTop: ["考核招聘过程指标", "考核招聘结果指标", " 考核招聘过程+招聘结果指标"],
      chargeSubTitlesBottom: ["每顾问每月固定收费", "按招聘offer数收费", "每顾问每月固定收费+招聘offer数收费"],
      processTitles: ["提交需求，洽谈合作", "制定方案，组建离岸团队", "开启服务，访寻推荐", "系统支持，数据监控"],
      processSubTitlesTop: [
        "提交合作需求，顾问快速响应，",
        "确定离岸项目经理和顾问，梳理",
        "招聘顾问整合招聘渠道，人才库匹",
        "HR SaaS系统支持，每日、周、月",
      ],
      processSubTitlesMid: [
        "洽谈合作方案",
        "客户需求，量化工作考核指标",
        "配推荐人选，并跟进后续面试入职",
        "度招聘数据汇报，把控招聘进度，",
      ],
      processSubTitlesBottom: ["", "", "", "服务数据可视化"],
      caseTitles: [
        "国内领先的消防管道解决方案供应商",
        "著名美股上市医药互联网公司",
        "国内领先的汽车智能研发设备制造商",
        "国内Top级房地产开发公司",
      ],
      caseSolution: [
        "科锐离岸外包的服务方式解决客户方现阶段需求，1名顾问作为HR角色访寻，并跟进候选人面试全流程，一位项目经理协调双方沟通问题，保证项目的正常推进。",
        "全渠道、全流程RPO模式，招聘端采用离岸招聘专员外包（过程付费）与猎头（结果付费）的结合。所有人员均选用科锐人事代理服务，部分人员采用派遣模式，灵活客户用人方式，优化结构。",
        "长期放一个离岸顾问做招聘支持，依据客户招聘量，不断的和客户沟通协调增加离岸项目成员，对于所出现问题及时解决商讨合适的合作方案，最终帮其完成研发人员在年低的扩充。",
        "华南大区从总部到区域，每月稳定16个全职离岸HC服务于客户重点区域，承担招聘前期的所有访寻工作，进行关键职位人才储备，定向Mapping，主动匹配优质候选人资源，全渠道候选人盘活。",
      ],
      caseAchievements: [
        "现阶段一个月内向客户输送40余人，成功帮助客户关掉3个城市需求，offer转化率6：1，客户对于推荐质量和数量都表示满意。",
        "在客户方HR招聘团队5人编制的情况下，配合客户方于六个月内如期交付620HC业务团队搭建及50HC职能团队搭建。",
        "为客户精准提供各类职能、研发人员，如光学工程师、嵌入式软件开发以及高级机械工程师等，每月保证稳定的推荐数量，其中为其访寻到对标公司对标职位的人选，成功关掉十余HC。",
        "配合客户方如期做好业务团队搭建及人才储备。获得客户方集团总部高度认可，服务质量口口相传，目前95%南方区域均已采用离岸合作方式。每个离岸顾问月均给客户的推荐量达到40+，月均offer数3-5个，为客户节约了招聘成本。",
      ],
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleShouldOpacity;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.carousel = null;
  }

  handleScroll = (e) => {
    const header = document.getElementsByClassName("home-header")[0];
    const { scrollTop } = e.srcElement.scrollingElement;
    const opacity = Math.round((scrollTop * 10) / 450) / 10;
    header.style.background = `rgba(14, 121, 251, ${opacity})`;
  };

  //服务内容-人员招聘-次级标题循环
  renderService = (count, serviceTitles, serviceTitlesTop, serviceTitlesMid, serviceTitlesBottom) => {
    const arr = [];
    for (let i = 1; i <= count; i++) {
      if (i === 1) {
        const subTopArr = [];
        const subMidArr = [];
        const subBottomArr = [];
        for (let j = 0; j < serviceTitlesTop[0].length; j++) {
          subTopArr.push(<span>{serviceTitlesTop[0][j]}</span>);
          subMidArr.push(<span>{serviceTitlesMid[0][j]}</span>);
          subBottomArr.push(<span>{serviceTitlesBottom[0][j]}</span>);
        }
        arr.push(
          <div className="service-unit">
            <img src={require(`assets/imgs/offshore/offshore-service-${i}.png`)} />
            <div className="service-title">{serviceTitles[i - 1]}</div>
            <div className="service-sub-title">{subTopArr}</div>
            <div className="service-sub-title">{subMidArr}</div>
            <div className="service-sub-title">{subBottomArr}</div>
          </div>
        );
      } else {
        arr.push(
          <div className="service-unit">
            <img src={require(`assets/imgs/offshore/offshore-service-${i}.png`)} />
            <div className="service-title">{serviceTitles[i - 1]}</div>
            <div className="service-sub-title">{serviceTitlesTop[i - 1]}</div>
            <div className="service-sub-title">{serviceTitlesMid[i - 1]}</div>
            <div className="service-sub-title">{serviceTitlesBottom[i - 1]}</div>
          </div>
        );
      }
    }
    return arr;
  };

  //解决问题
  renderSolve = (count, solveTitles, solveSubTitlesTop, solveSubTitlesBottom) => {
    const arr = [];
    for (let i = 1; i <= count; i++) {
      arr.push(
        <div className="solve-unit">
          <div className="solve-unit-left">
            <div className="solve-unit-left-title">{solveTitles[i - 1]}</div>
            <div className="solve-unit-left-sub-title">{solveSubTitlesTop[i - 1]}</div>
            <div className="solve-unit-left-sub-title">{solveSubTitlesBottom[i - 1]}</div>
          </div>
          <div className="solve-unit-right">
            <img src={require(`assets/imgs/offshore/solve-${i}.png`)} />
          </div>
        </div>
      );
    }
    return arr;
  };

  //服务价值
  renderWorth = (count, worthTitles, worthSubTitlesTop, worthSubTitlesBottom) => {
    const arr = [];
    for (let i = 1; i <= count; i++) {
      arr.push(
        <div className="worth-unit">
          <img src={require(`assets/imgs/offshore/worth-${i}.png`)} />
          <div className="worth-unit-title">{worthTitles[i - 1]}</div>
          <div className="worth-unit-sub-title">{worthSubTitlesTop[i - 1]}</div>
          <div className="worth-unit-sub-title">{worthSubTitlesBottom[i - 1]}</div>
        </div>
      );
    }
    return arr;
  };

  //收费方式
  renderCharge = (count, chargeTitles, chargeSubTitlesTop, chargeSubTitlesBottom) => {
    const arr = [];
    for (let i = 1; i <= count; i++) {
      arr.push(
        <div className="charge-unit">
          <img src={require(`assets/imgs/offshore/charge-${i}.png`)} />
          <div className="charge-unit-title">{chargeTitles[i - 1]}</div>
          <div className="charge-unit-sub-title">{chargeSubTitlesTop[i - 1]}</div>
          <div className="charge-unit-sub-title">{chargeSubTitlesBottom[i - 1]}</div>
        </div>
      );
    }
    return arr;
  };

  //服务流程
  renderProcess = (count, processTitles, processSubTitlesTop, processSubTitlesMid, processSubTitlesBottom) => {
    const arr = [];
    for (let i = 1; i <= count; i++) {
      i % 2 === 0
        ? arr.push(
            <div className="process-unit">
              <img src={require(`assets/imgs/home/home-right-arrow.png`)} />
            </div>
          )
        : arr.push(
            <div className="process-unit">
              <img src={require(`assets/imgs/offshore/process-${(i + 1) / 2}.png`)} />
              <div className="process-unit-title">{processTitles[(i - 1) / 2]}</div>
              <div className="process-unit-sub-title">{processSubTitlesTop[(i - 1) / 2]}</div>
              <div className="process-unit-sub-title">{processSubTitlesMid[(i - 1) / 2]}</div>
              <div className="process-unit-sub-title">{processSubTitlesBottom[(i - 1) / 2]}</div>
            </div>
          );
    }
    return arr;
  };

  //客户案例
  renderCase = (count, caseTitles, caseSolution, caseAchievements) => {
    const arr = [];
    for (let i = 0; i < count; i++) {
      arr.push(
        <div className="case-carousel" key={i}>
          <div className="case-carousel-header">
            <div className="case-carousel-header-left">
              <img src={require(`assets/imgs/offshore/case-header-logo.png`)} />
            </div>
            <div className="case-carousel-header-right">{caseTitles[i]}</div>
          </div>
          <div className="case-carousel-content">
            <div className="case-carousel-content-left">
              <div>解决方案</div>
              <div>{caseSolution[i]}</div>
            </div>
            <img src={require(`assets/imgs/offshore/case-arrow.png`)} />
            <div className="case-carousel-content-right">
              <div>服务成果</div>
              <div>{caseAchievements[i]}</div>
            </div>
          </div>
        </div>
      );
    }

    return arr;
  };

  onChange = (a, b, c) => {
    console.log(a, b, c);
  };

  handlePrev = () => {
    this.handleShouldOpacity();
    this.carousel.prev();
  };

  handleNext = () => {
    this.handleShouldOpacity();
    this.carousel.next();
  };

  handleShouldOpacity = () => {
    const currentIndex = document.getElementsByClassName("slick-current")[0].getAttribute("data-index");
    const arrows = document.getElementsByClassName("case-arrow");
    const leftArrows = arrows[0];
    const rightArrow = arrows[1];
    if (currentIndex == 0) {
      leftArrows.style.opacity = 0.5;
      rightArrow.style.opacity = 1;
    } else if (currentIndex == 3) {
      leftArrows.style.opacity = 1;
      rightArrow.style.opacity = 0.5;
    } else {
      leftArrows.style.opacity = 1;
      rightArrow.style.opacity = 1;
    }
  };

  getNextRoute = () => {
    const { user } = this.props;
    return user && user.info && user.info.id ? "/position?from=offshoreOutsource" : "/register?from=offshoreOutsource";
  };

  render() {
    const {
      serviceTitles,
      serviceTitlesTop,
      serviceTitlesMid,
      serviceTitlesBottom,
      solveTitles,
      solveSubTitlesTop,
      solveSubTitlesBottom,
      worthTitles,
      worthSubTitlesTop,
      worthSubTitlesBottom,
      chargeTitles,
      chargeSubTitlesTop,
      chargeSubTitlesBottom,
      processTitles,
      processSubTitlesTop,
      processSubTitlesMid,
      processSubTitlesBottom,
      caseTitles,
      caseSolution,
      caseAchievements,
    } = this.state;
    const NextUrl = this.getNextRoute();
    return (
      <Fragment>
        {/* 离岸外包 */}
        <div className="offshoreOutsource-content-1">
          <div className="banner-content">
            <div className="offshore-1-left">
              <div className="offshore-banner-title">离岸外包 共享Recruiter</div>
              <div className="offshore-banner-sub-title">即用即停 灵活高效</div>
              <Link to={NextUrl} id="register-top">
                申请试用
              </Link>
            </div>
            <img className="offshore-1-right" src={require(`assets/imgs/offshore/offshore-banner.png`)} />
          </div>
        </div>

        {/* 服务内容 */}
        <div className="offshoreOutsource-content-2">
          <div className="offshore-service-title">服务内容</div>
          <div className="offshore-service-sub-title">
            即派招聘顾问以远程离岸方式，支持企业开展访寻推荐、面试安排、市场分析、人才库盘点等招聘相关工作。
          </div>
          <div className="service-content">
            {this.renderService(3, serviceTitles, serviceTitlesTop, serviceTitlesMid, serviceTitlesBottom)}
          </div>
        </div>

        {/* 解决问题 */}
        <div className="offshoreOutsource-content-3">
          <div className="solve-title">解决问题</div>
          <div className="solve-content">
            {this.renderSolve(4, solveTitles, solveSubTitlesTop, solveSubTitlesBottom)}
          </div>
        </div>

        {/* 服务价值 */}
        <div className="offshoreOutsource-content-4">
          <div className="worth-title">服务价值</div>
          <div className="worth-content">
            {this.renderWorth(4, worthTitles, worthSubTitlesTop, worthSubTitlesBottom)}
          </div>
        </div>

        {/* 收费方式 */}
        <div className="offshoreOutsource-content-5">
          <div className="charge-title">收费方式</div>
          <div className="charge-content">
            {this.renderCharge(3, chargeTitles, chargeSubTitlesTop, chargeSubTitlesBottom)}
          </div>
        </div>

        {/* 服务流程 */}
        <div className="offshoreOutsource-content-6">
          <div className="process-title">服务流程</div>
          <div className="process-content">
            {this.renderProcess(7, processTitles, processSubTitlesTop, processSubTitlesMid, processSubTitlesBottom)}
          </div>
        </div>

        {/* 客户案例 */}
        <div className="offshoreOutsource-content-7">
          <div className="case-title">客户案例</div>
          <div className="case-content">
            <img className="case-arrow" src={require(`assets/imgs/offshore/case-left.png`)} onClick={this.handlePrev} />
            <Carousel
              ref={(value) => {
                this.carousel = value;
              }}
            >
              {this.renderCase(4, caseTitles, caseSolution, caseAchievements)}
            </Carousel>
            <img
              className="case-arrow"
              src={require(`assets/imgs/offshore/case-right.png`)}
              onClick={this.handleNext}
            />
          </div>
        </div>

        {/* 申请试用共享Recruiter */}
        <div className="offshoreOutsource-content-8">
          <div className="share-title">申请试用共享Recruiter</div>
          <Link to={NextUrl} id="register-bottom">
            申请试用
          </Link>
        </div>
      </Fragment>
    );
  }
}

export default OffshoreOutsource;
