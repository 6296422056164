import React from "react";

import "./AuthBg.scss";

export default function AuthBg(props) {
  const {history} = props;
  return (
    <div className="auth-bg">
      <img onClick={() => history.push("/")} className="g-logo" src={require("assets/imgs/auth/login-logo.png")} alt="logo"/>
      <div className="user-panel">
        <div className="panel-left">
          <img className="login-left-bg" src={require("assets/imgs/auth/login-left-bg.png")}/>
        </div>
        <div className="panel-right">
          <div className="panel-content">
            {props.children}
          </div>
          <div className="service-phone">客服电话：400-600-6181</div>
        </div>
      </div>
       
    </div>
  )
}