import xhr from "./index";

export function reqPosList(param) {
  return xhr({ url: "/hrpc/v1/jds", body: param, method: "GET" });
}

export function getPosDetail(param) {
  return xhr({ url: "/hrpc/v1/jds/" + param.id, body: param, method: "GET" });
}

export function getPosRecord(param) {
  return xhr({ url: "/hrpc/v1/jd/logs", body: param, method: "GET" });
}

export function getPosProfile(param) {
  return xhr({ url: "/hrpc/v1/jd/profiles", body: param, method: "GET" });
}

export function getProfileLogs(param) {
  return xhr({ url: "/hrpc/v1/jd/profile-logs", body: param, method: "GET" });
}