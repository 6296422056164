/**
 * Created by CI11979 on 2017/8/1.
 */
import React, { Component } from "react";
import "./agreement.scss";

export default class ServiceClause extends Component {
  render() {
    return (
      <div className="serviceClauseClass inner-page-wrapper">
        <div className="container">
          <div className="introduction introductionFirstCover">
            <h3>即派用户服务协议</h3>
          </div>
          <div className="introductionContent">
            <li>
              <pre>
                <p>欢迎您注册成为即派用户！即派用户服务协议（以下简称“本协议”）是由科锐数字科技（苏州）有限公司（以下简称“即派”或者“我们”）和您签订包括求职端，猎头端（Geebox）,招聘端同时生效。</p>
              </pre>
            </li>
          </div>
          <div className="introduction">
            <h3>第1条 即派用户服务协议的确认</h3>
          </div>
          <div className="introductionContent">
            <li>
              <pre>
                <p>
                1、您在注册、登陆使用即派提供的各项服务之前，请您仔细阅读并理解本协议全部内容，做出您认为适当的选择。我们努力用通俗易懂、简单明了的文字表达，同时对于在本协议中与您的权益存在重大关系的条款，采用粗体字进行标注以提示您注意。<b>当您点击“注册”或者“登录”或其他方式确认即表示您已经仔细阅读并完全理解、同意本协议项下的全部条款。</b>
                </p>
                <p>
                2、如果我们对本协议进行修改，我们将在即派网站提前予以公布。<b>若您不同意修改后的协议，您有权停止使用即派服务或者注销您的即派账户；双方协商一致的，也可另行变更协议内容。您如果继续“登录”或其他方式确认，使用即派提供的服务，即表示已经仔细阅读并完全理解、同意修改后的协议。</b>
                </p>
              </pre>
            </li>
          </div>
          <div className="introduction">
            <h3>第2条 账户的注册、使用、注销</h3>
          </div>
          <div className="introductionContent">
            <li>
              <pre>
                <p>
                1、 在您开始注册使用即派服务前，您应当具备中华人民共和国法律规定的与您的行为<b>相适应的民事行为能力。</b>
                </p>
                <p>
                2、 您同意本协议的全部条款并按照登陆页面上的提示提供<b>您本人真实、准确、有效信息</b>并完成全部注册程序后，才能成为即派用户。我们通过即派小程序，为即派用户提供网络服务。
                </p>
                <p>
                （1）如果您是求职用户，建议您尽量完善和公开您的简历信息以更好进行求职。如<b>您同意在您选择公开简历时，在您选择公开简历范围的即派用户可以付费下载您的简历以获取您填写的联系方式。</b>
                </p>
                <p>
                （2）如果您是招聘用户，您需要完成个人<b>实名认证并关联企业</b>，才能能使用即派提供的相关服务。为了平台的安全运行，保证信息的真实、有效，我们视情况定期或不定期核查已经通过认证的招聘用户，以验证认证信息的真实有效性。<b>如在核查过程中发现招聘用户提供的信息不符合规定或虚假或无效的，我们有权对该用户及关联单位采取包括但不限于扣除资源、限制登录、暂停使用、终止合作永久封禁账户等平台管制措施。</b>
                </p>
                <p>
                （3）如果您是乙方（人力资源服务商）用户，您需要完成个人<b>实名认证并关联企业</b>，才能能使用即派提供的相关服务。如您申请入驻服务商专区，视为<b>即派用户可以查看您的企业信息及联系方式。如在核查过程中发现乙方用户提供的信息不符合规定或虚假或无效的，我们有权对该用户及关联单位采取包括但不限于扣除资源、限制登录、暂停使用、终止合作永久封禁账户等平台管制措施。</b>
                </p>
                <p>
                3、 您应妥善保证自己的帐号和密码，您应当对以其账户进行的所有活动和事件负法律责任。如您是企业用户，您对其认证后关联的个人用户的所有行为承担连带责任。
                </p>
                <p>
                <b>4、 您在使用即派网络存储平台或享受即派提供的服务过程中，应当遵守国家法律法规以及所有与即派服务有关的网络协议、规定、产品规则或程序，不得有下列任何行为，否则即派有权不通知您自行采取措施，包括但不限于删除用户发布的内容，限制、暂停用户使用，或终止合作永久封禁账户等措施：</b>
                </p>
                <p>
                <b>（1）提供虚假信息注册的（包括但不限于冒充其他人、单位）或者仿冒、混淆他人账号对外展示名称、头像或者发布让人容易产生混淆、误解的内容的行为；</b>
                </p>
                <p><b>（2）在即派平台上骚扰、辱骂、歧视、威胁他人或存在其他不正当行为；</b></p>
                <p><b>（3）通过任何技术手段侵入我们的软件、网站、数据库等系统或者干扰即派产品和/或服务正常运行的；</b></p>
                <p><b>（4）侵犯即派或第三方的专利权、著作权、商标权、名誉权或其他任何合法权益的行为；</b></p>
                <p><b>（5）未经即派许可，在即派平台发布广告或营销行为；</b></p>
                <p><b>（6）利用即派平台发布、传输任何新闻信息，包括但不限于有关政治、经济、军事、外交等社会公共事务的报道、评论，以及有关社会突发事件的报道、评论；</b></p>
                <p><b>（7）其他违反国家法律法规以及所有与即派服务有关的网络协议、规定、产品规则或程序的行为。</b></p>
                <p>5、 用户还需遵守即派公布《派币规则》、《职位发布规则》。</p>
                <p>6、 您在与即派上公布信息的个人或公司沟通时，保证在即派平台上进行。</p>
                <p><b>7、 您在即派发布的职位信息具有效期，即派有权下线有效期届满的职位信息。</b></p>
                <p><b>8.您购买或者即派赠送的线上服务、派币或资源均有有效期，有效期结束后线上服务和/或资源自动清零、服务权限降低，资源有效期不可中断或延期，即派其他服务条款或优惠活动另有规定的除外。</b></p>
                <p>9、 即派有权拒绝为与本单位经营同类业务、有业务竞争关系或者其他利害关系的单位及个人提供服务。</p>
                <p>10、 您可以拨打我们的客户服务热线400-600-6181，向我们申请注销您即派账户，我们的客服将协助您进行注销。在注销账户之后，我们将停止为您提供所有的产品和服务，并依据您的要求，删除您的个人信息，但法律法规另有规定的除外。</p>
              </pre>
            </li>
          </div>
          <div className="introduction">
            <h3>第3条 用户承诺</h3>
          </div>
          <div className="introductionContent">
            <li>
              <pre>
                <p>
                1、您应遵守中华人民共和国相关法律法规、所居住或者开展经营活动或者业务国家或者地区的法律法规，不得将我们的网络存储平台和/或服务用于非法目的，不也得以非法方式使用我们的网络存储平台和/或服务。
                </p>
                <p>2、您承诺在注册或者认证过程中，提供的资料合法、真实、有效。如提交的资料有任何变动，应该及时、准确更新。如因提交的资料不真实或者更新不及时而引发的问题，您应承担相应责任。</p>
                <p><b>3、您承诺在即派上传、发表、转载或者传输的内容合法、真实、有效，不得夸张描述或编造虚假信息，并对内容承担全部责任。您不得在即派的任何页面发布、转载、传送含有下列任何内容的信息，否则即派有权不通知您自行采取措施，包括但不限于删除用户发布的内容，限制、暂停使用，或终止合作永久封禁账户等措施：</b></p>
                <p>
                <b>（1）违反宪法确定的基本原则的；</b>
                </p>
                <p>
                <b>（2）危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的；</b>
                </p>
                <p>
                <b>（3）损害国家荣誉和利益的；</b>
                </p>
                <p>
                <b>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</b>
                </p>
                <p><b>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</b></p>
                <p>
                <b>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</b>
                </p>
                <p>
                <b>（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</b>
                </p>
                <p>
                <b>（8）侮辱或者诽谤他人，侵害他人合法权益的；</b>
                </p>
                <p>
                <b>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</b>
                </p>
                <p>
                <b>（10）含有歧视内容的，如民族歧视、地域歧视、性别歧视、宗教歧视等；</b>
                </p>
                <p>
                <b>（11）虚假、胁迫、猥亵、骚扰、中伤、粗俗、侵害他人隐私或其他有悖道德、令人反感的内容；</b>
                </p>
                <p><b>（12）含有其他违法违规、违反《职位发布规则》、违背善良风俗内容的内容。</b> </p>
              </pre>
            </li>
          </div>
          <div className="introduction">
            <h3>第4条 用户违规行为的处理</h3>
          </div>
          <div className="introductionContent">
            <li>
              <pre>
                <p>
                <b>1、您如违反使法律法规或本协议中的第二条、第三条任一条款的，即派有权要求改正或直接采取一切必要的措施。必要的措施包括但不限于删除用户张贴的内容、暂停或终止用户使用即派服务。被终止使用即派服务的用户已缴纳的费用不予以退还，若其账户中存在积分资源和用户等级的，则同步清零，资源、人选信息等不予退还。</b>
                </p>

                <p>
                2、用户违反即派公布《派币规则》、《职位发布规则》，根据对应规则进行处理。
                </p>
                <p>
                3、用户如违反法律法规或本协议约定，导致即派或其关联公司遭受损失（损失包括但不限于直接经济损失、行政罚款、维权成本），用户应赔偿即派或其关联公司的全部损失。
                </p>
                <p>4、即派未行使或延迟行使其在本协议权利并不构成对这些权利的放弃，单独或部分行使其在本协议项下的任何权利不妨碍任何其它权利的行使。即派随时有权要求用户继续履行义务并承担相应的违约责任。</p>
              </pre>
            </li>
          </div>
          <div className="introduction">
            <h3>第5条 用户的个人信息保护</h3>
          </div>
          <div className="introductionContent">
            <li>
              <pre>
                <p>在使用即派服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品服务。一旦您开始或继续使用，即表示您已充分理解并同意本政策。</p>
                <p>
                <b>1.您向我们提供的信息</b>
                </p>
                <p>
                <b>1.1.使用即派的必要信息</b>
                </p>
                <p>
                当您使用即派 时，我们要求您提供并收集以下您的个人信息。此等信息对于充分履行您和我们之间的约定的服务很有必要，并使得我们能够遵守我们的法律义务。没有此等信息，我们可能无法向您提供您所要求的全部服务。
                </p>
                <p>
                账号信息。在您注册即派 账号时，我们可能要求您提供姓名、邮箱地址、公司、手机号码等信息。简历资料和服务项目信息。为使用即派 的某些功能，我们可能会要求您提供额外信息，其中可能包含您的求职状态、居住地、工作经验和教育经历的部分必要内容等。
                </p>
                <p><b>1.2地理位置信息</b></p>
                <p>
                当您使用即派的某些功能时，我们可能会收集您的精确或大致位置信息，此等信息通过您的IP地址或移动设备的GPS数据来确定，以便为您提供更好的用户体验。多数移动设备允许您在设备的设置菜单中控制或禁用应用对位置服务的使用。即使当您不使用应用时，如果您的设置或设备权限允许此等连接开启，我们仍可能会收集此等信息。当然，如果您在安装及/或使用过程中拒绝授予我们相应权限的，我们并不会记录您上述对应的信息。
                </p>
                <p><b>2我们如何使用所收集的信息</b></p>
                <p>我们遵循“合法、正当、必要”的原则使用、存储和处理您的信息（包括个人信息），以提供、了解、改进和发展即派 ，创建和维护一个受到信任的、更安全的环境，并遵守我们的法律义务。其中，如涉及您的个人化信息，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。我们有权使用已经去标识化的信息； 并在不透露您个人信息的前提下，对用户数据库进行分析、利用。</p>
                <p><b>2.1提供个性化定制、评估和改进我们的广告和营销</b></p>
                <p> 根据您的求职意向、搜索行为等信息偏好（基于您提供给我们的简历信息、您与即派的互动、从第三方获得信息以及您的搜索和其他记录）进行行为分析，向您发送短信、邮件、平台消息推送、微信和电话邀约等营销内容、广告以及您可能感兴趣的其他信息（包括与即派 、合作伙伴活动及服务相关的信息）。
                为给您提供更多您可能感兴趣的信息（包括投递邀请、面试提醒、推荐职位/企业等），我们可能会通过电话提醒、电话邀约或发送短信方式通知您关于简历投递、面试、职位、公司、以及线下招聘活动等信息。
                </p>
                <p>
                <b>3. 我们可能共享、转让、公开披露信息</b>
                </p>
                <p>3.1获得您的明确同意后，我们会与其他方共享您的个人信息。比如：当您主动投递简历给招聘企业，您的简历信息将分享给企业用户（即招聘企业），包括您在简历中主动填写的所有内容。</p>
                <p>3.2为便于我们基于关联账号共同向您提供服务，推荐您可能感兴趣的信息或保护即派关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司共享。</p>
                <p>3.3仅为实现本隐私权政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息，以提供更好的客户服务和用户体验。我们的合作伙伴必须遵守我们的数据隐私和安全要求，并且无权将共享的个人信息用于与产品或服务无关的其他用途。</p>
                <p>3.4 尤其注意：以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
                <p>1)依照法律、法规、法院命令、监管机构命令的要求，或根据政府行为、监管要求或请求； </p>
                <p>2)为执行相关服务协议或本政策、维护社会公共利益，为保护使用者、我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益合理且必要的用途；</p>
              </pre>
            </li>
          </div>
          <div className="introduction">
            <h3>第6条 版权声明</h3>
          </div>
          <div className="introductionContent">
            <li>
              <pre>
                <p>
                我们平台特有的标识、版面设计、编排方式等知识产权均属即派享有，未经即派许可，不得任意复制、转载或使用。
                </p>
              </pre>
            </li>
          </div>
          <div className="introduction">
            <h3>第7条    不可抗力以及免责</h3>
          </div>
          <div className="introductionContent">
            <li>
              <pre>
                <p>
                1、因遇不可抗力造成的即派无法为用户提供服务的，即派不承担任何法律责任，不可抗力包括但不限于：政府行为、自然灾害、战争、罢工、电脑病毒、黑客攻击、电信管理部门因技术调整导致网络中断等。
                </p>
                <p>2、用户理解即派无法保证网络服务任何条件下均不会中断，故即派对网络服务的及时性、安全性、准确性不作担保。同时，即派对服务器受病毒侵袭及其他故障侵扰免责。用户应做好数据备份，若发生使用本平台时的数据丢失的情况，即派免责。</p>
                <p>3、即派不能保证某一招聘信息会有一定数量的用户来浏览，也不能保证会有一位特定的用户来浏览。即派并不保证所有信息、文本、图形、链接及其它项目的绝对准确性和完整性，故仅供用户参考使用。</p>

                <p>4、用户承担通过即派登录到其他站点而形成的全部风险。同时了解通过即派之外与其他个人及用户的沟通不在即派控制范围内，其独立承担相应的后果。即派提供平台服务，故不担保用户发送给另一方用户的资料的真实性、精确性与可靠性。</p>

                <p>5、即派提供平台服务，任何用户通过即派自行发布的需求信息及其他产品或者服务，因其发布行为产生的法律后果由发布人承担；用户在通过即派得到资讯和信息后，与信息发布方所进行的任何交易均系其双方自主交易，双方若发生纠纷，皆与即派无关，即派不承担任何法律责任。</p>

              </pre>
            </li>
          </div>
          <div className="introduction">
            <h3>第8条    通知、送达</h3>
          </div>
          <div className="introductionContent">
            <li>
              <pre>
                <p>
                1、即派对于用户所有的通知均可通过【消息通知、电子邮件、短信、公告】，该等通知于发送之日视为已送达收件人。
                </p>
                <p>
                2、用户对于即派的通知应当通过即派如下正式公布的通信地址、电子邮件地址等联系信息进行送达。
                </p>
              </pre>
            </li>
          </div>
        </div>
      </div>
    );
  }
}
