import xhr from "./index";


export function login(param) {
  return xhr({ url: "/hrpc/v1/user/login", body: param, method: "POST" });
}

export function sendMsg(param) {
  return xhr({ url: "/hrpc/v1/user/send-msg", body: param, method: "POST" });
}

export function getUserInfo() {
  return xhr({
    url: "/hrpc/v1/user/info",
    method: "GET"
  });
}

export function getCompany(param) {
  return xhr({
    url: "/hrpc/v1/gb-companies",
    method: "GET",
    body: param,
  });
}

export function forgetPwd(param) {
  return xhr({ url: "/hrpc/v1/user/forget-pwd", body: param, method: "POST" });
}

export function resetPwd(param) {
  return xhr({ url: "/hrpc/v1/user/reset-pwd", body: param, method: "POST" });
}

export function regist(param) {
  return xhr({ url: "/hrpc/v1/user/register", body: param, method: "POST" });
}

export function logout() {
  return xhr({ url: "/hrpc/v1/user/logout", method: "GET" });
}

export function submitReview(param) {
  return xhr({ url: "/hrpc/v1/user/submit-review", body: param, method: "POST" });
}