import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Button,
  message,
  Upload,
  Row,
  Col
} from "antd";
import CustomIcon from "components/CustomIcon";
import SecondTabs from "components/SecondTabs/tabs";
import InputComplete from "components/InputComplete";
import ImgShow from "components/ImgShow";
import userModel from "store/reducers/userModel";
import * as userService from "services/userService";
import "./authentication.scss";

const FormItem = Form.Item;
const size = "default";
const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

const UploadCard = ({
  title,
  img
}) => {
  return (
    <React.Fragment>
      {
        img ? 
        <div className="re-upload">
          <img src={img.url}/>
          <div className="re-tip">重新上传</div>
        </div>
        :
        <div className="upload-card">
          <CustomIcon type="icon-plus" />
          <div className="ant-upload-text">{title}</div>
        </div>
      }
    </React.Fragment>
    
  );
};

function CompleteForm(props) {
  const { getFieldDecorator, setFieldsValue, validateFields } = props.form;
  const { userAction, history, user = {}, switchCheckStatus } = props;
  const { check_info = {}} = user;
  const [isSelectCom, setIsSelectCom] = useState(false);
  const [companyImg, setCompanyImg] = useState(null);
  const [personalImg, setPersonalImg] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(false);
  // check_info.check_status = 4;
  useEffect(() => {
    if (check_info.check_status !== 4) {
      if(check_info.license) {
        let result = {url: check_info.license};
        setCompanyImg({url: check_info.license})
        setFieldsValue({"license": result})
      }
      if(check_info.proof_pic) {
        let result = {url: check_info.proof_pic};
        setPersonalImg(result)
        setFieldsValue({"proof_pic": result})
      }
    }
  }, [check_info])

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitStatus(true)
    validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        values.license = companyImg.url;
        values.proof_pic = personalImg.url;
        userAction.completeUserInfo(values).then((res) => {
          if (res.code === 0) {
            switchCheckStatus(2)
          } else {
            message.error(res.errorMsg);
          }
          setSubmitStatus(false)
        });
      } else {
        setSubmitStatus(false)
      }
    });
  };
  const requestCompany = (param) => {    
    return new Promise((resolve, reject) => {
      userService.getCompany({
        name: param.name
      }).then(res => {
        let autoResult = {code: 0};
        if (res.code === 0) {
          autoResult.data = res.data.list && res.data.list.map(item => item.name);
          autoResult.dataSource = res.data.list;
        } else {
          autoResult.data = [];
          autoResult.dataSource = []
        }
        resolve(autoResult)
      })
    })
  }
  const onSelectCompany = (name, option) => {
    let optionSource = option.props.source;
    setFieldsValue({"company_short_name": optionSource.short_name})
    setIsSelectCom(true);
  }
  const onChangeCompany = (name) => {
    setIsSelectCom(false)
    setFieldsValue({"company_short_name": ""})
  }
  const personalUpload = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      const res = info.file.response;
      setPersonalImg(res.data)
    }
  }
  const companyUpload = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      const res = info.file.response;
      setCompanyImg(res.data)
    }
  }
  const beforeUpload = file => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("上传图片最大不超过5M");
    }
    return isLt5M;
  }
  const notAll = check_info.check_status === 0 || check_info.check_status === 1;
  return (
    <React.Fragment>
      {check_info.check_status === 4 && <div className="auth-error">
        <CustomIcon type="icon-close-circle-fill"/>
        <span>审核不通过：{check_info.unpass_reason}</span>
      </div>}
      <Form className="complete-auth-form auth-form" onSubmit={handleSubmit}>
        <FormItem className="form-item" label="姓名" {...formItemLayout}>
          {getFieldDecorator("user_name", {
            initialValue: notAll
                ? check_info.user_name
                : "",
            rules: [{ required: true, message: "请输入姓名" }],
          })(<Input placeholder="请输入姓名" size={size} />)}
        </FormItem>
        <FormItem className="form-item" label="担任职位" {...formItemLayout}>
          {getFieldDecorator("position", {
            initialValue: notAll
                ? check_info.position
                : "",
            rules: [
              { required: true, message: "请输入担任职位" },
            ],
          })(<Input placeholder="请输入担任职位" size={size} />)}
        </FormItem>
        <FormItem className="form-item" label="企业邮箱" {...formItemLayout}>
          {getFieldDecorator("email", {
            initialValue: notAll
                ? check_info.email
                : "",
            rules: [
              { required: true, message: "请输入企业邮箱" },
              {
                pattern: new RegExp(
                  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
                ),
                message: "请输入正确的邮箱格式",
              },
            ],
          })(<Input placeholder="请输入企业邮箱" size={size} />)}
        </FormItem>
        <FormItem className="form-item" label="手机号" {...formItemLayout}>
          {getFieldDecorator("phone", {
            initialValue: check_info.phone
                ? check_info.phone
                : "",
            rules: [
              { required: true, message: "请输入手机号" },
            ],
          })(<Input disabled={check_info.phone} placeholder="请输入手机号" size={size} />)}
        </FormItem>

        <FormItem className="form-item" label="公司名称" {...formItemLayout}>
          {getFieldDecorator("company_name", {
            initialValue: notAll
                ? check_info.company_name
                : "",
            rules: [{ required: true, message: "请输入公司名称" }],
          })(<InputComplete size={size}
              onSelect={onSelectCompany}
              onChange={onChangeCompany}
              requestFunc={requestCompany}
              placeholder="请输入公司名称"
              custom
              />)}
        </FormItem>
        <FormItem className="form-item" label="公司简称" {...formItemLayout}>
          {getFieldDecorator("company_short_name", {
            initialValue: notAll
                ? check_info.company_short_name
                : "",
            rules: [{ required: true, message: "请输入公司简称" }],
          })(<Input disabled={isSelectCom} placeholder="请输入公司简称" size={size} />)}
        </FormItem>
        <Row className="special-row">
          <Col className="special-col" span={12}>
            <FormItem className="form-item" label="认证资料"  wrapperCol={{span: 14}} labelCol={{span: 10}}>
              {getFieldDecorator("license", {
                // validateTrigger: "onSubmit",
                rules: [{ required: true, message: "请上传营业执照" } ],
              })(
                  <Upload
                    name="avatar"
                    beforeUpload={beforeUpload}
                    listType="picture-card"
                    className={`avatar-uploader ${companyImg ? 'has' : ''}`}
                    showUploadList={false}
                    name="attachment"
                    action="/hrpc/v1/common/upload"
                    onChange={companyUpload}
                  >
                    <UploadCard title="上传营业执照" img={companyImg}/>
                  </Upload>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem>
              {getFieldDecorator("proof_pic", {
                rules: [{ required: true, message: "请上传在职证明" }],
              })(
                  <Upload
                    name="avatar"
                    beforeUpload={beforeUpload}
                    listType="picture-card"
                    className={`avatar-uploader ${personalImg ? 'has' : ''}`}
                    showUploadList={false}
                    name="attachment"
                    action="/hrpc/v1/common/upload"
                    onChange={personalUpload}
                  >
                    <UploadCard title="上传在职证明" img={personalImg}/>
                  </Upload>
              )}
            </FormItem>
          </Col>
        </Row>
        <FormItem
          className="form-item btn-item"
          label=" "
          {...formItemLayout}
          colon={false}
        >
          <Button disabled={submitStatus} className="submit-btn" type="primary" block htmlType="submit">
            提交
          </Button>
        </FormItem>
      </Form>
      
    </React.Fragment>
  );
}

function Authing(props) {

  return (
    <div className="auth-status-ing">
      <CustomIcon type="icon-time-circle-fill"/>
      <div className="status-text">身份认证已提交，正在审核中....</div>
      <div className="contact-text">联系客服可申请快速审核 0512-67610835</div>
      <img className="auth-img" src={require("assets/imgs/auth-ing.png")}/>
      <div className="tip">扫码体验小程序</div>
    </div>
  )
}


function Authed({
  user
}) {
  const {check_info = {}} = user;
  const [previewImg, setPreviewImg] = useState();
  const onPreviewClose = () => {
    setPreviewImg("")
  }
  return (
    <div className="auth-status-ready">
      <div className="authed">
        <CustomIcon type="icon-check-circle-fill"/>
        <span>已认证</span>
      </div>
      <div className="auth-info">
        <Row className="info-row">
          <Col className="label" span={3}>
            姓名
          </Col>
          <Col>{user.user_name}</Col>
        </Row>
        <Row className="info-row">
          <Col className="label" span={3}>
            担任职位
          </Col>
          <Col>{check_info.position}</Col>
        </Row>
        <Row className="info-row">
          <Col className="label" span={3}>
            企业邮箱
          </Col>
          <Col>{check_info.email}</Col>
        </Row>
        <Row className="info-row">
          <Col className="label" span={3}>
            手机号
          </Col>
          <Col>{check_info.phone}</Col>
        </Row>
        <Row className="info-row">
          <Col className="label" span={3}>
            公司名称
          </Col>
          <Col>{check_info.company_name}</Col>
        </Row>
        <Row className="info-row">
          <Col className="label" span={3}>
            公司简称
          </Col>
          <Col>{check_info.company_short_name}</Col>
        </Row>
        <Row className="info-row">
          <Col className="label" span={3}>
            认证资料
          </Col>
          <Col className="pic-col">
            <img src={check_info.license} onClick={() => setPreviewImg(check_info.license)}/>
            <img src={check_info.proof_pic} onClick={() => setPreviewImg(check_info.proof_pic)}/>
          </Col>
        </Row>
      </div>
      {previewImg && <ImgShow previewSrc={previewImg} onCloseChild={onPreviewClose}/>}
    </div>
  )
}


@connect(
  ({ user }) => {
    return { user: user.info || {} };
  },
  {
    ...userModel.actions,
  }
)
export default class Center extends React.Component {

  state = {
    userStatus: null,
  };

  componentDidMount() {
    const { user } = this.props;
    document.title = "认证信息";
  }

  componentWillUnmount() {
  }

  switchCheckStatus = (status) => {
    this.setState({
      userStatus: status
    })
  }

  render() {
    const {
      history,
      user,
      completeUserInfo
    } = this.props;
    let { check_status } = user;
    const Completer = Form.create({})(CompleteForm);
    check_status = this.state.userStatus || check_status;
    // check_status = 4; 
    return (
      <div className=" inner-page-wrapper">
        <SecondTabs history={history} />
        <div className="center-content page-content">
          <div className="content-header">认证信息</div>
          <div className="auth-status-wrapper">
            {(check_status === 0 || check_status === 1|| check_status === 4) && <Completer userAction={{completeUserInfo}} user={user} switchCheckStatus={this.switchCheckStatus}/>}
            {check_status === 2 && <Authing />}
            {check_status === 3 && <Authed user={user}/>}
          </div>
        </div>
      </div>
    );
  }
}
