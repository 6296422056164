import React from "react";
import { connect } from "react-redux";
import { Button, Input, Table, Form, DatePicker  } from "antd";
import CustomIcon from "components/CustomIcon";
import SecondTabs from "components/SecondTabs/tabs";
import userModel from "store/reducers/userModel";
import * as supplierService from "services/supplierService";
import ProfileDialog from "./profile-dialog";
import moment from "moment";
import "./supplier.scss";

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const size = "default";

function SearchForm(props) {
  const { getFieldDecorator, getFieldValue, validateFields } = props.form;
  const { getSupplierList } = props;
  const rangeChange = (dates, dateStrings) => {
    getSupplierList({start_time: dateStrings[0], end_time: dateStrings[1]})
  }
  return (
    <Form className="search-form" layout="inline">
      <FormItem className="form-item" label="时间筛选" labelCol={{span: 4}} wrapperCol={{span: 14}}>
        {getFieldDecorator("time", {
          initialValue: [moment(), moment()],
        })(
          <RangePicker onChange={rangeChange} format="YYYY-MM-DD" placeholder={["开始日期", "结束日期"]}/>
        )}
      </FormItem>
    </Form>
  );
}

const Searcher = Form.create({})(SearchForm);

@connect(
  ({ user }) => {
    return { user: user };
  },
  {
    ...userModel.actions,
  }
)
export default class Supplier extends React.Component {
  state = {
    supplierGetParam: {page: 1, "per-page": 10},
    supplierList: [],
    supplierMeta: {},
    dialogVisible: false,
    dialogType: 0,
    hh_id: "",
  };
  componentDidMount() {
    document.title = "我的供应商";
    this.getSupplierList({
      start_time: moment().format("YYYY-MM-DD"),
      end_time: moment().format("YYYY-MM-DD"),
    })
  }

  componentDidUpdate(prevProps) {
  }

  goPosition = (record) => {
    const { history } = this.props;
    const { supplierGetParam } = this.state;
    history.push({
      pathname: "/position",
      state: {
        keyword: record.hh_name
      }
    });
  }
  goInputTalent = (record) => {
    const { history } = this.props;
    const { supplierGetParam } = this.state;
    history.push({
      pathname: "/resume",
      state: {
        enter_from: supplierGetParam.start_time,
        enter_to: supplierGetParam.end_time,
        jd_hh: record.hh_name
      }
    });
  }
  goFollowTalent = (record) => {
    const { history } = this.props;
    const { supplierGetParam } = this.state;
    history.push({
      pathname: "/resume",
      state: {
        follow_from: supplierGetParam.start_time,
        follow_to: supplierGetParam.end_time,
        jd_hh: record.hh_name
      }
    });
  }

  columns = [
    {
      title: "项目经理",
      dataIndex: "hh_name",
      ellipsis: true,
      width: 120,
      render: (text, record) => {
        return text;
      },
    },
    {
      title: "供应商",
      dataIndex: "company_name",
      ellipsis: true,
      width: 230,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "进行中职位",
      align: "center",
      dataIndex: "jd_processing_num",
      ellipsis: true,
      className: "num-column",
      width: 100,
      render: (text, record) => <span onClick={this.goPosition.bind(this, record)}>{text ? text : "-"}</span>,
    },
    {
      title: "录入人选",
      align: "center",
      dataIndex: "input_num",
      className: "num-column",
      ellipsis: true,
      width: 100,
      render: (text, record) => <span onClick={this.goInputTalent.bind(this, record)}>{text ? text : "-"}</span>,
    },
    {
      title: "跟进人选",
      align: "center",
      dataIndex: "follow_num",
      className: "num-column",
      width: 100,
      render: (text, record) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "共推荐",
      align: "center",
      dataIndex: "rmd_num",
      className: "num-column",
      width: 90,
      render: (text, record) => <span onClick={this.openDialog.bind(this, 1, record, text)}>{text ? text : "-"}</span>,
    },
    {
      title: "安排面试",
      align: "center",
      className: "num-column",
      dataIndex: "arrange_interview_num",
      width: 100,
      render: (text, record) => <span onClick={this.openDialog.bind(this, 2, record, text)}>{text ? text : "-"}</span>,
    },
    {
      title: "面试通过",
      align: "center",
      dataIndex: "interview_through_num",
      className: "num-column",
      width: 100,
      render: (text, record) => <span onClick={this.openDialog.bind(this, 3, record, text)}>{text ? text : "-"}</span>,
    },
    {
      title: "offer协调",
      align: "center",
      dataIndex: "offer_coordinate_num",
      width: 100,
      className: "num-column",
      render: (text, record) => <span onClick={this.openDialog.bind(this, 4, record, text)}>{text ? text : "-"}</span>,
    },
    {
      title: "入职",
      align: "center",
      dataIndex: "onboard_num",
      width: 90,
      className: "num-column",
      render: (text, record) => <span onClick={this.openDialog.bind(this, 5, record, text)}>{text ? text : "-"}</span>,
    },
  ];

  openDialog = (type, record, num) => {
    if (num <= 0) return;
    this.setState({
      dialogVisible: true,
      dialogType: type,
      supplierGetParam: {
        ...this.state.supplierGetParam,
        hh_id: record.hh_id,
        hh_name: record.hh_name
      }
    })
  }

  onDialogClose = () => {
    this.setState({
      dialogVisible: false,
    })
  }
 
  getSupplierList = (params) => {
    const {supplierGetParam} = this.state;
    params = {...supplierGetParam, ...params}
    this.setState({
      supplierGetParam: params
    })
    supplierService.reqSuppliersList(params).then(res => {
      if (res.code === 0) {
        this.setState({
          supplierList: res.data.list || [],
          supplierMeta: res.data._meta || {},
        })
      }
    })
  }

  pageChange = (page, pageSize) => {
    this.getSupplierList({
      page: page,
      "per-page": pageSize,
    });
  };

  pageSizeChange = (page, pageSize) => {
    this.getSupplierList({
      page: page,
      "per-page": pageSize,
    })
  };

  render() {
    const {
      history,
    } = this.props;
    const { supplierList, supplierMeta, dialogVisible, dialogType, supplierGetParam, hh_id } = this.state;

    return (
      <div className="supplier-page inner-page-wrapper">
        <SecondTabs history={history} />
        <div className="supplier-content-panel">
          <div className="supplier-wrapper">
            <div className="supplier-search">
              <Searcher getSupplierList={this.getSupplierList}/>
            </div>
            <Table
              rowKey="id"
              className="supplier-table"
              // rowClassName={(record) => {
              //   return activeKey === "1" && record.is_get ? "is-get" : "";
              // }}
              pagination={{
                size: "small",
                showSizeChanger: true,
                showQuickJumper: true,
                total: supplierMeta.totalCount,
                pageSize: supplierMeta.perPage || 10,
                onChange: this.pageChange,
                onShowSizeChange: this.pageSizeChange,
                current: supplierMeta.currentPage,
              }}
              columns={this.columns}
              dataSource={supplierList}
            />
            <ProfileDialog profileParams={supplierGetParam} visible={dialogVisible} dialogType={dialogType} onDialogClose={this.onDialogClose}/>
          </div>
        </div>
      </div>
    );
  }
}
