import React, {useContext, useEffect, useState}  from "react";
import DetailHead from "./DetailHead";
import DetailLeft from "./DetailLeft";
import DetailRight from "./DetailRight";

import * as positionService from "services/positionService";
import {PosContext} from '../PositionDetailDrawer';

import "./detail-drawer.scss";

export default function DetailLayout() {
  const posContext = useContext(PosContext);
  const [posDetail, setPosDetail] = useState({});
  useEffect(() => {
    positionService.getPosDetail({
      id: posContext.posId
    }).then(res => {
      if (res.code === 0) {
        setPosDetail(res.data);
      }
    })
  }, [posContext.posId])

  return (
    <React.Fragment>
      <DetailHead posDetail={posDetail}></DetailHead>
      <div className="detail-content-wrapper">
        <div className="left-wrapper">
          <DetailLeft posDetail={posDetail}/>
        </div>
        <div className="right-wrapper">
          <DetailRight posDetail={posDetail}/>
        </div>
      </div>
    </React.Fragment>
  );
}
