import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Tabs, message } from "antd";
import CustomIcon from "components/CustomIcon";
import PhoneCode from "./PhoneCode";
import AuthBg from "./AuthBg";
import userModel from "store/reducers/userModel";
import {PHONE_REG, VERIFY_CODE_REG} from 'utils/const';
import "./auth.scss";

const { TabPane } = Tabs;
const FormItem = Form.Item;
const size = "large";

function PhoneLoginForm(props) {
  const { getFieldDecorator, getFieldValue, validateFields } = props.form;
  const { userAction, history, visible, handleSubmit } = props;
  return (
    <Form style={{ display: visible ? "" : "none" }} className="login-phone-form verify-form" onSubmit={(e) => handleSubmit(e, validateFields)}>
      <FormItem className="form-item" label="">
        {getFieldDecorator("phone", {
          rules: [
            { required: true, message: "请输入手机号" },
            { pattern: new RegExp(PHONE_REG), message: "请输入正确手机号码" },
          ]
        })(
          <Input prefix={<CustomIcon type="icon-ic_ph" style={{ fontSize: "16px" }} />}
            placeholder="请输入手机号"
            size={size}
          />
        )}
      </FormItem>
      <div className="special-input">
        <FormItem className="form-item" label="">
          {getFieldDecorator("verify_code", {
            rules: [
              { required: true, message: "请输入验证码" },
              { pattern: new RegExp(VERIFY_CODE_REG), message: "请输入正确验证码" },
            ]
          })(
            <Input prefix={<CustomIcon type="icon-ic_yzm" style={{ fontSize: "16px" }} />}
              placeholder="请输入验证码"
              size={size}
              maxLength={6}
            />
          )}
        </FormItem>
        <PhoneCode className="register-get-code" formRef={props.form} userAction={userAction} />
      </div>
      <Button className="login-btn" type="primary" block htmlType="submit" size={size}>登录</Button>
    </Form>
  );
}

function PasswordLoginForm(props) {
  const { getFieldDecorator, getFieldValue, validateFields } = props.form;
  const { userAction, history, visible, handleSubmit, goReset } = props;
  return (
    <Form style={{ display: visible ? "" : "none" }} className="login-password-form verify-form" onSubmit={(e) => handleSubmit(e, validateFields)}>
      <FormItem className="form-item" label="">
        {getFieldDecorator("phone", {
          rules: [
            { required: true, message: "请输入手机号码" },
            { pattern: new RegExp(PHONE_REG), message: "请输入正确手机号码" },
          ]
        })(
          <Input prefix={<CustomIcon type="icon-ic_ph" style={{ fontSize: "16px" }} />}
            placeholder="请输入手机号码"
            size={size}
          />
        )}
      </FormItem>
      <div className="special-input">
        <FormItem className="form-item password-item" label="">
          {getFieldDecorator("password", {
            rules: [
              { required: true, message: "请输入密码" },
            ]
          })(
            <Input prefix={<CustomIcon type="icon-ic_mima" style={{ fontSize: "16px" }} />}
              // onBlur={this.onBlurHandle}
              placeholder="请输入密码" type="password"
              size={size}
            />
          )}
        </FormItem>
        <div className="register-get-code" style={{ color: "#0E79FB", fontSize: "13px", cursor: "pointer" }} onClick={goReset}>忘记密码</div>
      </div>
      <Button className="login-btn" type="primary" block htmlType="submit" size={size}>登录</Button>
    </Form>
  )
}

const PhoneLogin = Form.create({})(PhoneLoginForm);
const PasswordLogin = Form.create({})(PasswordLoginForm);

export function LoginPanel(props) {
  // const { getFieldDecorator, getFieldValue, validateFields } = props.form;
  const { userAction, history, tabType, modalClick } = props;
  const { location={}} = history;
  const [activeKey, setActiveKey] = useState("1");
  const tabChange = (activeKey) => {
    setActiveKey(activeKey);
  }

  useEffect(() => {
    if (tabType) {
      setActiveKey(tabType);
    }
  }, [tabType]);

  const handleSubmit = (e, validateFields) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values, history, userAction);
        userAction.phoneLogin(values).then(res => {
          if (res.code === 0) {
            let userInfo = res.data;
            //进入登录前所想跳入页面
            if(location.state&&location.state.target){
              history.push(history.location.state.target);
              return
            }
            if (userInfo.check_status === 3) {
              history.push("/position")
            } else {
              history.push("/authentication")
            }
          } else {
            message.error(res.errorMsg);
          }
        })
      }
    });
  }
  const goRegister = () => {
    if (modalClick) {
      modalClick(2);
    } else {
      history.push("/register");
    }
  }
  const goReset = () => {
    if (modalClick) {
      modalClick(3)
    } else {
      history.push("/reset");
    }
  }
  return (
    <div className="login-content">
      <div className="title">登录Geebox</div>
      <Tabs activeKey={activeKey} onChange={tabChange} className="login-tab">
        <TabPane tab="账号密码登录" key="1">
        </TabPane>
        <TabPane tab="验证码登录" key="2">
        </TabPane>
      </Tabs>
      <div>
        <PhoneLogin visible={activeKey === "2"} userAction={userAction} history={history} handleSubmit={handleSubmit}/>
        <PasswordLogin goReset={goReset} visible={activeKey === "1"} userAction={userAction} history={history} handleSubmit={handleSubmit}/>
        <div className="go-register">没有账号？ <span style={{cursor: "pointer"}} onClick={goRegister}><span>立即注册</span><CustomIcon type="icon-ic_enter"/></span></div>
      </div>
    </div>
  );
}

@connect(
  ({ user }) => {
    return { user: user };
  },
  {
    ...userModel.actions,
  }
)
export default class AuthPanel extends React.Component {
  state = {
    tabType: null,
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.state && location.state.tabType) {
      this.setState({
        tabType: location.state.tabType
      }, () => {
        location.state.tabType = null;
      })
    }
  }

  render () {
    const {history, phoneLogin} = this.props;
    const {tabType} = this.state;
  
    return (
      <AuthBg history={history}>
        <LoginPanel history={history} userAction={{phoneLogin}} tabType={tabType}/>
      </AuthBg>
    );
  }
}
