import React from "react";
import { Select, Form, Table, Modal } from "antd";

import PositionDetailDrawer from "pages/position/PositionDetailDrawer";
import CustomIcon from "components/CustomIcon";
import * as supplierService from "services/supplierService";

const Option = Select.Option;
const FormItem = Form.Item;
const confirm = Modal.confirm;

export default class ProfileDialog extends React.PureComponent {
  state = {
    searchParam: {},
    profileData: {},
    drawerId: 0,
    drawerVisible: false,
    drawerType: 1,
  };

  title = {
    "1": "推荐报告",
    "2": "安排面试",
    "3": "面试通过",
    "4": "offer协调",
    "5": "onboard",
  }

  timeStr = {
    "1": "推荐时间",
    "2": "面试时间",
    "3": "面试时间",
    "4": "offer时间",
    "5": "onboard时间",
  }

  componentDidUpdate(prevProps) {
    const {visible} = this.props;
    if (prevProps.visible !== visible && visible) {
      this.searchList({"per-page": 5});
    }
  }

  searchList = (params) => {
    const {profileParams, dialogType} = this.props;    
    let param = {...this.state.searchParam, ...profileParams, type: dialogType, ...params};
    this.setState({
      searchParam: param
    })
    supplierService.reqSupplierData(param).then(res => {
      if (res.code === 0) {
        this.setState({
          profileData: res.data
        })
      }
    })
  };

  onShowSizeChange = (page, pageSize) => {
    this.searchList({
      page: page,
      "per-page": pageSize,
    })
  };

  pageChange = (page, pageSize) => {
    this.searchList({
      page: page,
      "per-page": pageSize,
    })
  }

  /**
   * @description 关闭或打开弹窗
   */
  handleCancel = e => {
    this.props.onDialogClose();
    this.setState({
      searchParam: {},
      profileData: {},
    })
  };

  /**
   * @description 打开onboard弹窗
   * @param {object} record
   */
  openModal = record => {
    if (record.status !== 4) return;
    this.setState({
      openStatus: 5,
      personInfo: {
        ...record,
        id: record.jd_profile_id
      }
    });
  };
  
  /**
   * @description 打开onboard弹窗
   * @param {object} record
   */
  showModal = () => {
    this.setState({
      openStatus: 0,
      personInfo: {}
    });
  };

  openResume = (record) => {
    window.open("/resume/" +  record.profile_id)
  }

  openPosDrawer = (record) => {
    this.setState({
      drawerId: record.jd_id,
      drawerVisible: true,
      drawerType: 1,
    })
  }

  onDrawerClose = () => {
    this.setState({
      drawerId: 0,
      drawerVisible: false,
    })
  }

  render() {
    const { profileData, drawerId, drawerVisible, drawerType } = this.state;
    const {
      visible,
      dialogType,
      profileParams
    } = this.props;
    const { _meta = {}, extra = {} } = profileData;
    const paginationProps = {
      size: "small",
      showSizeChanger: true,
      showQuickJumper: true,
      total: _meta.totalCount,
      pageSize: _meta.perPage || 10,
      pageSizeOptions: ['5', '10', '20', '30'],
      onShowSizeChange: this.onShowSizeChange,
      onChange: this.pageChange,
      showTotal: total => `总计${total}个`
    };
    const { total = {} } = extra;
    /**
     * @description 列表选项
     */
    let columns = [
      {
        title: "候选人",
        dataIndex: "name",
        key: "name",
        align: "left",
        width: 90,
        // ellipsis: true,
        render: (text, record) => (
          <div title={text}>
            <CustomIcon
              type={`${record.sex === 1 ? "icon-nan" : "icon-nv1"}`}
              style={{
                fontSize: 15,
                verticalAlign: "bottom",
                paddingRight: "7px"
              }}
            />
            <span onClick={() => this.openResume(record)} className="name ellipsis">{text}</span>
          </div>
        )
      },
      {
        title: "年龄",
        dataIndex: "age",
        key: "age",
        align: "left",
        ellipsis: true,
        width: 60,
        render: text => <div>{text ? text : "-"}</div>
      },
      {
        title: "职位",
        key: "position_name",
        dataIndex: "position_name",
        align: "left",
        ellipsis: true,
        width: 100
      },
      {
        title: "最近工作单位",
        key: "last_job_company_name",
        dataIndex: "last_job_company_name",
        align: "left",
        ellipsis: true,
        width: 200,
        render: text => <div className="ellipsis">{text ? text : "-"}</div>
      },
      {
        title: "学历",
        key: "education",
        dataIndex: "education",
        align: "left",
        ellipsis: true,
        width: 60,
        render: text => <div className="ellipsis">{text ? text : "-"}</div>
      },
      {
        title: "推荐职位",
        key: "jd_name",
        dataIndex: "jd_name",
        align: "left",
        ellipsis: true,
        width: 100,
        render: (text, record) => <div onClick={() => this.openPosDrawer(record)} className="pos ellipsis">{text ? text : "-"}</div>
      },
      {
        title: this.timeStr[dialogType],
        key: "event_time_str",
        dataIndex: "event_time_str",
        align: "left",
        width: 160,
      },
    ];
    // if (dialogType !== 4) {
      columns.push({
        title: "当前状态",
        key: "status_name",
        dataIndex: "status_name",
        align: "left",
        width: 80,
      })
    // }

    return (
      <Modal
        width={900}
        title={this.title[dialogType] + "-" + profileParams.hh_name}
        visible={visible}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        footer={false}
        className="flowDialog-dialog"
        destroyOnClose
      >
        <Table
          className="small sup-dialog-table"
          rowKey={record => record.jd_profile_id}
          columns={columns}
          dataSource={profileData.list}
          pagination={paginationProps}
        />
        <PositionDetailDrawer onClose={this.onDrawerClose} visible={drawerVisible} posId={drawerId} posType={drawerType}/>
      </Modal>
    );
  }
}
