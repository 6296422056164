import xhr from "./index";
import cacheOptions from "../utils/cacheOptions";

export function getCommonDataWithoutCache(dataNameArray = []) {
  return xhr({
    url: `/hpc/v1/common/static`,
    body: { data_name: dataNameArray.join(",") },
    method: "GET"
  })
}

export const getCommonData = cacheOptions((dataNameArray = []) =>
  xhr({
    url: `/hpc/v1/common/static`,
    body: { data_name: dataNameArray.join(",") },
    method: "GET"
  })
);