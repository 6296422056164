import React from "react";
import { connect } from "react-redux";
import { Form, Input, Button, message, Tabs } from "antd";
import userModel from "store/reducers/userModel";
import PhoneCode from "./PhoneCode";
import AuthBg from "./AuthBg";
import CustomIcon from "components/CustomIcon";
import {PHONE_REG, VERIFY_CODE_REG} from 'utils/const';
import "./auth.scss"

const { TabPane } = Tabs;
const FormItem = Form.Item;
const size = "large";

function ResetForm(props) {
  const { getFieldDecorator, getFieldValue, validateFields } = props.form;
  const { userAction, history, modalClick } = props;
  const handleSubmit = (e) => {
    e.preventDefault();
    
    validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        userAction.forgetPwdReset(values).then(res => {
          if (res.code === 0) {
            history.push("/login");
          } else {
            message.error(res.errorMsg);
          }
        })
      }
    });
  }

  const leastSame = (rule, value, callback) => {
    let password = getFieldValue("password");
    let rePassword = getFieldValue("re_password");
    console.log(value, password, rePassword, "leastSame")
    if (rule.field === "password") {
      if (value && rePassword && value != rePassword) {
        callback("两次密码不一致");
      } else {
        callback()
      }
      return;
    } else if (rule.field === "re_password") {
      if (value && password && value != password) {
        callback("两次密码不一致");
      } else {
        callback()
      }
      return;
    }
    callback("")
    
  }
  const newPasswordChange = (e) => {
    let rePassword = getFieldValue("re_password");
    let password = e.target.value;
    console.log(rePassword, password, "213")
    setTimeout(() => {
      if (rePassword ) {
        validateFields(["re_password"])
      }
    }, 0);
    
  }
  const newRPasswordChange = (e) => {
    let password = getFieldValue("password");
    let rePassword = e.target.value;
    setTimeout(() => {
      if (password ) {
        validateFields(["password"])
      }
     
    }, 0);
  }
  const goLogin = () => {
    if (modalClick) {
      modalClick(1);
    } else {
      history.push("/login");
    }
  }

  return (
    <div className="login-content">
      <div className="title">忘记密码</div>
      <Tabs className="login-tab">
        <TabPane tab="通过手机号修改" key="1">
        </TabPane>
      </Tabs>
      <Form className="reset-form verify-form" onSubmit={handleSubmit}>
        <FormItem className="form-item" label="" >
          {getFieldDecorator("phone", {
            rules: [
              { required: true, message: "请输入手机号" },
              { pattern: new RegExp(PHONE_REG), message: "请输入正确手机号码"},
            ]
          })(
            <Input prefix={<CustomIcon type="icon-ic_ph" style={{fontSize: "16px"}}/>}
              placeholder="请输入手机号"
              size={size}
            />
          )}
        </FormItem>
       
        <div className="special-input">
          <FormItem className="form-item" label="" >
            {getFieldDecorator("verify_code", {
              rules: [
                { required: true, message: "请输入验证码" },
                { pattern: new RegExp(VERIFY_CODE_REG), message: "请输入正确验证码" },
              ]
            })(
              <Input prefix={<CustomIcon type="icon-ic_yzm" style={{fontSize: "16px"}}/>}
                placeholder="请输入验证码"
                size={size}
                maxLength={6}
              />
            )}
          </FormItem>
          <PhoneCode className="register-get-code" formRef={props.form} />
        </div>
        <FormItem className="form-item" label="" >
          {getFieldDecorator("password", {
            rules: [
              { required: true, message: "请输入新密码" },
            ]
          })(
            <Input prefix={<CustomIcon type="icon-ic_mima" style={{ fontSize: "16px" }} />}
              placeholder="请输入新密码"  type="password"
              size={size} 
              onChange={newPasswordChange}
            />
          )}
        </FormItem>
        {/* <FormItem className="form-item" label="" >
          {getFieldDecorator("re_password", {
            // initialValue: editClueData.company_name,
            rules: [
              { required: true, message: "请输入新密码", validateTrigger: "onChange" },
              {
                validator: leastSame
              }
            ]
          })(
            <Input prefix={<CustomIcon type="icon-ic_mima" style={{ fontSize: "16px" }} />}
              placeholder="请输入密码(至少6位，数字、字母或'_')"  type="password"
              size={size}
              onChange={newRPasswordChange}
            />
          )}
        </FormItem> */}
        <Button className="submit-btn" size={size} type="primary" block htmlType="submit">确定</Button> 
        <div className="accept-login">
          <div className="go-login"><span style={{cursor: "pointer"}} onClick={goLogin}><a>立即登录</a><CustomIcon type="icon-ic_enter"/></span></div>
        </div>
      </Form>
    </div>
  );
}

@connect(
  ({ user }) => {
    return { user: user };
  },
  {
    ...userModel.actions,
  }
)
export default class Reset extends React.Component {

  render() {
    const { history, forgetPwdReset } = this.props;
    const Reseter = Form.create({})(ResetForm);
    return (
      <AuthBg history={history}>
        <Reseter userAction={{forgetPwdReset}} history={history}/>
      </AuthBg>
    );
  }
}
