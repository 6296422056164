/*
 * @Descripttion: 
 * @Date: 2021-04-12 10:58:38
 * @LastEditors: ci25578
 * @LastEditTime: 2021-06-22 21:28:46
 */
import React, {useState} from "react";
import { Link } from "react-router-dom";
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';

export default function BasicFooter() {
  const [youzhiHover, setYouzhiHover]= useState(false);
  const [gboxHover, setGboxHover]= useState(false)
  const mouseYouzhiEnter = () => {
    setYouzhiHover(true)
  }
  const mouseYouzhiLeave = () => {
    setYouzhiHover(false)
  }
  const mouseGboxEnter = () => {
    setGboxHover(true)
  }
  const mouseGboxLeave = () => {
    setGboxHover(false)
  }
  const overPackOptions = {
    always: false,
    playScale: 0.1
  };
  return (
    <div className="layout-exfooter-wrapper">
      <OverPack {...overPackOptions}>
        <div className="layout-exfooter">
          <QueueAnim className="layout-exfooter-inner"
            type="bottom"
            key="block" >
            <div className="product left" key="1">
              <div className="title">关于</div>
                <div className="our-xcx" onMouseEnter={mouseGboxEnter} onMouseLeave={mouseGboxLeave}>
                  <a><span>即派盒子</span><span className="special"></span></a>
                  {gboxHover && <img className="foot-gbox" src={require("assets/imgs/foot-gbox.png")} />}
                </div>
            </div>
            <div className="about left" key="2">
              <div className="title">协议与规则</div>
              <div className="about-item"><Link to="/agreement" target="_blank">服务协议</Link></div>
            </div>
            <div className="contact left" key="3">
              <div className="title">联系我们</div>
              <div className="about-item">邮箱：Bobxu@careerintlinc.com</div>
              <div className="about-item">网址：https://passport.geetemp.com/</div>
              <div className="about-item">电话：400-600-6181</div>
            </div>
            
            <div className="service service-xcx right" key="5">
              <img src={require("assets/imgs/home/we-service-number.png")}/>
              <div className="name">服务号</div>
            </div>
            <div className="service right" key="4">
              <div><img className="footer-logo-img" src={require("assets/imgs/home/footer-logo.png")}/></div>
              <img src={require("assets/imgs/home/we-applets.png")}/>
              <div className="name">小程序</div>
            </div>
          </QueueAnim>
          <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="footer">
          <div className="split-line"></div>
          </TweenOne>
        </div>
      </OverPack>
      <div className="friend-link">
        <div className="report">朝阳区人力资源与社会保障局 监督电话:57596212，65090445</div>
        <span className="icp">©2014-2016</span>
        <a href="http://beian.miit.gov.cn" className="icp" target="_blank" rel="noopener noreferrer">苏ICP备14059286号-2</a>
        <a href="https://www.geetemp.com/geetemp/default/business-license.jpg" className="icp" target="_blank" rel="noopener noreferrer">营业执照</a>
        <a href="https://www.geetemp.com/geetemp/default/hrs-license.jpg" className="icp" target="_blank" rel="noopener noreferrer">服务许可证名称320510190032</a>
        <span className="icp">科锐数字科技（苏州）有限公司 版权所有</span>
      </div>
    </div>
  );
}
